:root{
    --dwp:calc(100vw/1512);
    --dwpm:calc(100vw/390);
    --dhpm:calc(100vh/844);
}
.section4to5{
    position: absolute;
    bottom: calc((var(--dwp)*577*-1));
}
.section4to5m{
    position: absolute;
    bottom: calc((var(--dhpm)*207*-1));
}
.section4to5-container{
    position: relative;
    width: 100%;
}
.section4-container{
    position: relative;
    background: url("../../assets/images/sec4bkgrnd.png");
    background-size: cover;
    flex-direction: column;
    display: flex;
    align-items: center;

}
.section4-main-container{
    position: relative;
    width: 100%;
}
.section4-main-container-b{
    position: relative;
    width: 1512px;
}
.section4-part-1-1{
    display: flex;
    flex-direction: column;
}
.section4-part-1-1 h3{
    font-family: 'SkModernist-Bold';
    text-transform: none;
    font-size:calc(var(--dwp2)*41);
    color:rgba(24, 67, 81, 1) ;
    margin-left:calc(var(--dwp)*136) ;
    margin-bottom:calc(var(--dwp)*136);
    margin-top: calc(var(--dwp)*344);

}

.section4-part-1-1-main-description{
    position: relative;
    display: flex;

}
.section4-part-1-1-description-1{
    width:calc(var(--dwp2)*698) ;
    margin-left:calc(var(--dwp)*180);
    margin-bottom:calc(var(--dwp)*136) ;
    margin-right:calc(var(--dwp)*136);
   display: flex;
   flex-direction: column;
}
.section4-part1-row{
    display: flex;
    flex-direction: row;
}
.section4-part-1-1-description-1 p {
    font-family: 'SkModernist-Regular';
    font-size:calc(var(--dwp2)*34);
    line-height: calc(var(--dwp2)*40.8);
    margin-bottom:calc(var(--dwp2)*64);
    color: rgba(24, 67, 81, 1);

}

.section4-part-1-1-description-1 li{
    font-family: 'SkModernist-Regular';
    font-size: calc(var(--dwp2)*22);
    line-height: calc(var(--dwp2)*26.4);
    margin-bottom:calc(var(--dwp2)*10);
    color:rgba(39, 42, 39, 1)
}

.section4-image1-main{
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;

}
.section4-image1{
    height:calc(var(--dwp2)*578);
}
.section4-image1-b{
    height:calc(var(--dwp2)*578);
    border-radius: 41px;
}
.section4-part-2-2{
    display: flex;
    flex-direction: column;
}
.section4-part2-row{
    display: flex;
    flex-direction: row;
}
.section4-image2-main{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    

}
.section4-image2{
    height:calc(var(--dwp2)*934);
    margin-bottom:calc(var(--dwp)*409) ;
}
.section4-image2-b{
    height:calc(var(--dwp2)*934);
    border-radius: 41px;
    margin-bottom:calc(var(--dwp)*409) ;
}

.section4-part-2-2-main-description{
    position: relative;
    display: flex;
}   
.section4-part-2-2-description-1{
    width:calc(var(--dwp2)*698) ;
    margin-top: calc(var(--dwp)*136);
    margin-left:calc(var(--dwp)*136);
    margin-right:calc(var(--dwp)*221);
    display: flex;
    flex-direction: column;
}

.section4-part-2-2-description-1 li {
    font-family: 'SkModernist-Regular';
    font-size: calc(var(--dwp2)*22);
    line-height: calc(var(--dwp2)*26.4);
    color: rgba(39, 42, 39, 1);
    margin-bottom:calc(var(--dwp2)*10);

}
.section4-part-2-2-description-1 ul {
    margin-bottom:calc(var(--dwp2)*136);

}

@media screen and (max-width: 500px) {
 
    .section4-container{
        position: relative;
        background: url("../../assets/images/sec4bkgrnd.png");
        background-size: cover;
        flex-direction: column;
        display: flex;

    }
    .section4-main-container{
        margin-top: 0;
        position: relative;
        width: 100%;
        
    }
    
    .section4-part-1-1{
        display: flex;
        flex-direction: column;
    }
    .section4-gap{
    height: calc(var(--dhpm)*199);
    width: 100%;

    }
    .section4-part-1-1 h3{
        font-family: 'SkModernist-Bold';
        text-transform: none;
        font-size:calc(var(--dwpm)*34);
        color:rgba(24, 67, 81, 1) ;
        margin-left:calc(var(--dwpm)*22) ;
        margin-bottom:calc(var(--dhpm)*123);
        margin-top: calc(var(--dwpm)*0);

    }
    
    .section4-part-1-1-main-description{
        position: relative;
        display: flex;
        margin-bottom:calc(var(--dhpm)*70);
    
    }
    .section4-part-1-1-description-1{
        width: 100%;
        margin-left:calc(var(--dwpm)*22) ;
        margin-bottom:calc(var(--dwpm)*22) ;
        margin-left:calc(var(--dwpm)*22) ;
       display: flex;
       flex-direction: column;
    }
    .section4-part1-row{
        display: flex;
        flex-direction: column;
    }

    .section4-part-1-1-description-1 p {
        font-family: 'SkModernist-Regular';
        font-size:calc(var(--dwpm)*22);
        line-height: calc(var(--dwpm)*26.4);
        color: rgba(24, 67, 81, 1);
    
    }
    
    .section4-part-1-1-description-1 li{
        font-family: 'SkModernist-Regular';
        font-size: calc(var(--dwpm)*17);
        line-height: calc(var(--dwpm)*20.4);
        color:#272a27;
        margin-bottom: calc(var(--dwpm)*10);
    
    }
    
    .section4-image1-main{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    
    }
    .section4-image1{
        height:calc(var(--dwpm)*285);
        margin-bottom: calc(var(--dhpm)*76);
    
    }
    
    .section4-part-2-2{
        display: flex;
        flex-direction: column;
    }
    .section4-part2-row{
        display: flex;
        flex-direction: column;
    }
    .section4-image2-main{
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    
    }
    .section4-image2{
        height:calc(var(--dwp)*934);
        height:calc(var(--dwp)*4);
        width: auto;
        margin-bottom:calc(var(--dwp)*0) ;
    }
    .section4-part-2-2-main-description{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }   
    .section4-part-2-2-description-1{
        width: 100%;
        margin-left:calc(var(--dwpm)*22) ;
        margin-bottom:calc(var(--dwpm)*22) ;
        margin-right:calc(var(--dwpm)*22) ;
        display: flex;
        flex-direction: column;
    }
    
    .section4-part-2-2-description-1 li {
        font-family: 'SkModernist-Regular';
        font-size: calc(var(--dwpm)*17);
        line-height: calc(var(--dwpm)*20.4);
        color:#272a27;
        margin-bottom: calc(var(--dwpm)*10);

    }
    .section4-part-2-2-description-1 ul {
        margin-bottom:calc(var(--dhpm)*76);
    
    }
    .section45t{
        margin-top: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        background: transparent;
        align-items: flex-end;
        bottom:calc(var(--dhpm)*200*-1);
        z-index: 99;
        height: calc(var(--dwpm)*340);;
    }
    .section45t1{
        position: relative;
        height: calc(var(--dwpm)*322);
        width: calc(var(--dwpm)*138);
        margin-right:calc(var(--dwpm)*22) ;
        margin-bottom: calc(var(--dwpm)*60) ;
    }
    .section45t2{
        position: relative;
        height: calc(var(--dwpm)*522);
        width: calc(var(--dwpm)*230);

    }
}