* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  background-color: #f9f3f3;
  color: #272a27;
  font-weight: 300;
  font-size: 20px;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}

@font-face {
  font-family: 'SkModernist-Bold';
  src: local('SkModernist-Bold'), url(./fonts/SkModernist-Bold.otf) format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'SkModernist-Regular';
  src: local('SkModernist-Regular'), url(./fonts/SkModernist-Regular.otf) format('truetype');
}

.clearfix {zoom: 1}

.clearfix:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}


/* Big Tablets to 1200px (widths smaller than the 1140px row) */
@media only screen and (max-width: 1200px) {

}


/* Small Tablet to Big Tablets: 768px to 1023px */
@media only screen and (max-width: 1023px) {
  html, body { font-size: 18px; }
}


/* Small Phones to Small Tablets: 481px to 767px */
@media only screen and (max-width: 767px) {
  html, body { font-size: 16px; }
}


/* Small Phone: 0 to 480px */
@media only screen and (max-width: 480px) {

}