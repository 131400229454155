footer {
    background: #5f605e;
    padding: 50px;
    font-size: 80%;
    font-family: 'SkModernist-Regular';
}

.footer-nav {
   
}
.logoFoot{
    height: calc(var(--df) * 70);
    width: calc(var(--df) * 80);
    position: relative;
    display: flex;
    left:calc(var(--dwp)*10*-1);
    
}
.social-links {
    list-style: none;
    float: right;
}

.footer-nav li,
.social-links li {
    display: inline-block;
    margin-right: 20px;
}

.footer-nav li:last-child,
.social-links li:last-child {
    margin-right: 0;
}

.footer-nav li a:link,
.footer-nav li a:visited,
.social-links li a:link,
.social-links li a:visited {
 
}

.footer-nav li a:hover,
.footer-nav li a:active,
.social-links li a:hover,
.social-links li a:active {
}

.social-links li a:link,
.social-links li a:visited {
    font-size: 160%;
}

footer p {

    color: #e3ccc5;
    text-align: center;
    margin-top: 20px;
}

/* Big Tablets to 1200px (widths smaller than the 1140px row) */
@media only screen and (max-width: 1200px) {

}


/* Small Tablet to Big Tablets: 768px to 1023px */
@media only screen and (max-width: 1023px) {

}
.mainFooter{
display: flex;
flex-direction: row;
text-decoration: none;

}
.mainFooter a{
    color: #e3ccc5;
    font-size: 15px;
    margin-bottom: 15px;
    text-decoration: none;
    border-bottom:0px solid red; 
}



.footerPart1{
    position: relative;
    width: 25%;
    display: flex;
    flex-direction: column;
    height: 250px;
    align-items: center;
    flex: 1;
    }
.footerPart2{
    align-items: center;
    width: 25%;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 250px;
    }
.footerPart3{
    align-items: center;
    width: 25%;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 250px;
    text-decoration: none;

    }
.footerPart4{
    align-items: center;
    width: 25%;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 250px;
    }

    @media screen and (max-width: 500px) {
        .mainFooter{
            display: flex;
            flex-direction: row;
            text-decoration: none;
            
            }
            .mainFooter a{
                text-align: center;
                color: #e3ccc5;
                font-size: calc(var(--dwpm)*13);
                text-decoration: none;
                border-bottom:0px solid red; 
            }
            
            
            .logoFoot{
                height: calc(var(--dwpm) * 50);
                width: calc(var(--dwpm) * 50);
                position:absolute;
                display: flex;
                left:calc(var(--dwpm)*20);
                bottom:calc(var(--dwpm)*0)
            }
            .footerPart1{
                align-items: center;
                position: relative;
                display: flex;
                flex-direction: column;
                height: calc(var(--dwpm)*220);
                margin-top:calc(var(--dwpm)*25);

                width: 25%;
            }
            .footerPart2{
                align-items: center;
                width: 25%;
                display: flex;
                flex-direction: column;
                height: calc(var(--dwpm)*220);
                margin-top:calc(var(--dwpm)*25);
                }
            .footerPart3{
                align-items: center;
                width: 25%;
                display: flex;
                flex-direction: column;
                height: calc(var(--dwpm)*220);
                text-decoration: none;
                margin-top:calc(var(--dwpm)*25);

                }
            .footerPart4{
                align-items: center;
                width: 25%;
                display: flex;
                flex-direction: column;
                height: calc(var(--dwpm)*220);
                margin-top:calc(var(--dwpm)*25);

                }

                footer {
                    background: #5f605e;
                    padding: calc(var(--dwpm)*10);
                    
                }
    }



