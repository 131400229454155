:root{
    --dwp:calc(100vw/1512);
    --dwpm:calc(100vw/390);
    --dhpm:calc(100vh/844);
}

.section7-container{
    background: url("../../assets/images/sec7bkgrnd.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.section7-title {
    display: flex;
    width: 100%;
}
.section7-box1-container{
    position: relative;
    display: flex;
    margin-bottom:calc(var(--dwp)*221) ;
}
.section7-box1{
    position: relative;
    display: flex;
    flex-direction: row;
    background: rgba(249, 243, 243, 0.62);
    box-shadow: 2px 2px 8px rgba(39, 42, 39, 0.23);
    border-radius: 41px;
    width: calc(var(--dwp2)*1100);
    height: calc(var(--dwp2)*420);
}

.section7-box1-1{
    display: flex;
    flex-direction: column;
}
.section7-box1-1-up{
    display: flex;
    flex-direction: column;
    margin-left: calc(var(--dwp2)*60);
    margin-top: calc(var(--dwp2)*60);
}
.section7-box1-1-down{
    display: flex;
    margin-top: calc(var(--dwp2)*30);
    flex-direction: column;
    margin-left: calc(var(--dwp2)*48);
    margin-right: calc(var(--dwp2)*146);
    max-width: calc(var(--dwp2)*560)
}
.section7-box1-1 a{
    color:#184351;
    font-family: 'SkModernist-Bold';
    font-size: calc(var(--dwp2)*34);
    font-weight: 700;
    line-height: calc(var(--dwp2)*40.8);
    text-align: left;
    margin-bottom: calc(var(--dwp2)*10);

    
  

}
.section7-box1-1 li{
    color: #272A27;
    font-family: 'SkModernist-Bold';
    font-size: calc(var(--dwp2)*22);
    font-weight: 400;
    line-height: calc(var(--dwp2)*26.4);
    text-align: left;
    list-style: inside;
    margin-bottom: calc(var(--dwp2)*5);


}
.section7-img1{
    height: calc(var(--dwp2)*420) ;
    width: calc(var(--dwp2)*420) ;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;

}
.section7-box1-2{
    position: absolute;
    right: 0;
}

.section7-box2-container{
    position: relative;
    display: flex;
    margin-bottom:calc(var(--dwp)*357) ;
}

.section7-box2{
    position: relative;
    display: flex;
    width: calc(var(--dwp2) * 1100);
    height: calc(var(--dwp2) * 420);
    background: url("../../assets/images/Rectangle\ 161.png");
    background-size: cover;
    border-radius: 41px;
    filter: drop-shadow(32px 32px 4px rgba(39, 42, 39, 0.23));

}

.nyc-offer {
    position: absolute;
    top:calc(var(--dwp2)*(-50));
    left:calc(var(--dwp2)*(-19));
    height: calc(var(--dwp2)*570);
    width: calc(var(--dwp2)*457);
}

.nyc-offer-text{
    position: absolute;
    right:calc(var(--dwp2)*60);
    top:calc(var(--dwp2)*35);
    height: calc(var(--dwp2)*368);
    width: calc(var(--dwp2)*560);
}

.sec7-logo-container{
    position: relative;
    display: flex;
}



.section7-dill-logo{
    position: absolute;
    height:calc(var(--dwp2)*213);
    width: calc(var(--dwp2)*186);
    bottom: calc(var(--dwp2)* 0);
    left:calc(var(--dwp2)* 200);
}

.section7-title h5{
    font-family: 'SkModernist-Bold';
    font-size: calc(var(--dwp2)*41);
    line-height: calc(var(--dwp2)*49.2);
    color:#272A27;
    margin-left: calc(var(--dwp2)*136);
    margin-top: calc(var(--dwp2)*675);
    margin-bottom: calc(var(--dwp)*232);
}
.section7-table-container{
    display: flex;
    /* Note: backdrop-filter has minimal browser support */
    flex-direction: column;
    margin-bottom: calc(var(--dwp)*375);
}

.section7-table-part1{
display: flex;
flex-direction: column;
}
.section7-table-part2{
    display: flex;
    flex-direction: column;
    }
   
.section7-table-part2 h5{
    font-family: 'SkModernist-Bold';
    font-weight: 700;
    line-height: calc(var(--dwp2)*31.2);
    font-size:calc(var(--dwp2)*26);
    border-bottom:1px solid  #272A27;;
    padding-bottom: calc(var(--dwp2)*12);
    padding-top: calc(var(--dwp2)*12);
    color: #184351;
}
.section7-table-part2 h4{
    font-family: 'SkModernist-Bold';
    font-weight: 700;
    line-height: calc(var(--dwp2)*31.2);
    font-size:calc(var(--dwp2)*26);
    border-bottom:1px solid  #272A27;;
    padding-bottom: calc(var(--dwp2)*12);
    color: #184351;
}
.section7-table-part1-up{
    display: flex;
    flex-direction: row;
}
.section7-table-part1-down{
    display: flex;
    flex-direction: row;
}

    .table-part1{
    display: flex;
    align-items: center;
    height:calc(var(--dwp2)*180);
    width: calc(var(--dwp2)*556)
}

.table-part1 a{
    font-family: 'SkModernist-Bold';
    font-weight: 700;
    line-height: calc(var(--dwp2)*30);
    font-size:calc(var(--dwp2)*25);
}

 .table-part2{
    border-top-right-radius:41px;
    border-top-left-radius:41px;
    background: #F5F6BB;
    display: flex; 
    width: calc(var(--dwp2)*136);
    align-items: center;
    flex-direction: column;
    }
    .table-part2-22{
        border-top-right-radius:41px;
        border-top-left-radius:41px;
        background: #C7EDFE;
        display: flex; 
        width: calc(var(--dwp2)*136);
        align-items: center;
        flex-direction: column;
        }
        .table-part2-3{
            border-top-right-radius:41px;
            border-top-left-radius:41px;
            background: #A7BADB;
            display: flex; 
            width: calc(var(--dwp2)*136);
            align-items: center;
            flex-direction: column;
            }
            .table-part2-4{
                border-top-right-radius:41px;
                border-top-left-radius:41px;
                background: #FFB9B9;
                display: flex; 
                width: calc(var(--dwp2)*136);
                align-items: center;
                flex-direction: column;
                }

.table-part1-2{
    display: flex;
    align-items: center;
    border-bottom:1px solid  #272A27;;
    height:60px;
    width: calc(var(--dwp2)*556);
    color: #184351;
}
.table-part1-2 a{
    font-family: 'SkModernist-Bold';
    font-weight: 700;
    line-height: calc(var(--dwp2)*30);
    font-size:calc(var(--dwp2)*25);
}
.table-part2-2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--dwp2)*136);
    }
    .table-part2-2 a{
        font-family: 'SkModernist-Bold';
        font-weight: 700;
        line-height: calc(var(--dwp2)*21.6);
        font-size:calc(var(--dwp2)*18);
        text-align: center;
        }
.part2-table{
    display: flex;
    flex-direction: row;
}
.part2-table-container{
    display: flex;
    flex-direction: column;
   

    
}
.part2-table-left-first{
    border-top-left-radius: 20px;
    padding-left:calc(var(--dwp2)*41);
    padding-top:calc(var(--dwp2)*41);
    background: #F9F3F3;
    width: calc(var(--dwp2)*556)
}
.part2-table-left{
    padding-left:calc(var(--dwp2)*41);
    background: #F9F3F3;
    width: calc(var(--dwp2)*556)
    }
    .part2-table-left-inner{
        padding-left:calc(var(--dwp2)*41);
        background: #F9F3F3;
        width: calc(var(--dwp2)*556)
    }
    .part2-table-left-last{
        border-bottom-left-radius: 20px;
        padding-left:calc(var(--dwp2)*41);
        background: #F9F3F3;
        width: calc(var(--dwp2)*556)
    }
    .part2-table-left-last li{
        padding-right:calc(var(--dwp2)*41);
        padding-top:calc(var(--dwp2)*12) ;
        padding-bottom: calc(var(--dwp2)*41);
        font-family: 'SkModernist-Bold';
        font-weight: 700;
        line-height: calc(var(--dwp2)*24);
        font-size:calc(var(--dwp2)*20);
        list-style: inside;
        color:#272A27;

    }
 
.part2-table-left li {
    padding-right:calc(var(--dwp2)*41);
    border-bottom:1px solid  #272A27;;
    padding-top:calc(var(--dwp2)*12) ;
    padding-bottom: calc(var(--dwp2)*12);
    font-family: 'SkModernist-Bold';
    font-weight: 700;
    line-height: calc(var(--dwp2)*24);
    font-size:calc(var(--dwp2)*20);
    list-style: inside;
    color:#272A27;
}
.part2-table-left-inner li {
    padding-left: calc(var(--dwp2)*60);
    padding-right:calc(var(--dwp2)*41);
    border-bottom:1px solid  #272A27;;
    padding-top:calc(var(--dwp2)*12) ;
    padding-bottom: calc(var(--dwp2)*12);
    font-family: 'SkModernist-Bold';
    font-weight: 700;
    line-height: calc(var(--dwp2)*24);
    font-size:calc(var(--dwp2)*20);
    list-style: inside;
    color:#272A27;
}
.part2-table-left p {
    padding-right:calc(var(--dwp2)*41);

    border-bottom:1px solid  #272A27;;
    padding-top:calc(var(--dwp2)*12) ;
    padding-bottom: calc(var(--dwp2)*12);
    font-family: 'SkModernist-Regular';
    font-weight: 700;
    line-height: calc(var(--dwp2)*24);
    font-size:calc(var(--dwp2)*20);
}
.part2-table-right{
    background: #F5F6BB;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom:1px solid  #272A27;;
    width: calc(var(--dwp2)*136);
    }
    .part2-table-right2{
        background: #C7EDFE;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom:1px solid  #272A27;;
        width: calc(var(--dwp2)*136);
    }
        .part2-table-right3{
            background: #A7BADB;

            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom:1px solid  #272A27;;
            width: calc(var(--dwp2)*136);
        }
            .part2-table-right4{
                background: #FFB9B9;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom:1px solid  #272A27;;
                width: calc(var(--dwp2)*136);
            }
.part2-table-right p{
    font-family: 'SkModernist-Bold';
    font-weight: 700;
    font-size:calc(var(--dwp2)*18);
}
.part2-table-right img{
    height:calc(var(--dwp2)*15.75) ;
    width:calc(var(--dwp2)*18) ;
}
.part2-table-right2 p{
    font-family: 'SkModernist-Bold';
    font-weight: 700;
    font-size:calc(var(--dwp)*18);
}
.part2-table-right2 img{
    height:calc(var(--dwp2)*15.75) ;
    width:calc(var(--dwp2)*18) ;
}
.part2-table-right3 p{
    font-family: 'SkModernist-Bold';
    font-weight: 700;
    font-size:calc(var(--dwp2)*18);
}
.part2-table-right3 img{
    height:calc(var(--dwp2)*15.75) ;
    width:calc(var(--dwp22)*18) ;
}
.part2-table-right4 p{
    font-family: 'SkModernist-Bold';
    font-weight: 700;
    font-size:calc(var(--dwp2)*18);
}
.part2-table-right4 img{
    height:calc(var(--dwp)*15.75) ;
    width:calc(var(--dwp)*18) ;
}
.part2-table-right4-last{
    background: #FFB9B9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(var(--dwp2)*136);
    border-bottom-right-radius: 20px;
    }
    .part2-table-right4-last p{
        font-family: 'SkModernist-Bold';
        font-weight: 700;
        font-size:calc(var(--dwp2)*18);
    }
    .part2-table-right4-last img{
        height:calc(var(--dwp2)*15.75) ;
        width:calc(var(--dwp2)*18) ;
    }
    .part2-table-right3-last{
        background: #A7BADB;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(var(--dwp2)*136);
        }
        .part2-table-right3-last p{
            font-family: 'SkModernist-Bold';
            font-weight: 700;
            font-size:calc(var(--dwp2)*18);
        }
        .part2-table-right3-last img{
            height:calc(var(--dwp2)*15.75) ;
            width:calc(var(--dwp2)*18) ;
        }
        .part2-table-right2-last{
            background: #C7EDFE;
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(var(--dwp2)*136);
            }
            .part2-table-right2-last p{
                font-family: 'SkModernist-Bold';
                font-weight: 700;
                font-size:calc(var(--dwp2)*18);
            }
            .part2-table-right2-last img{
                height:calc(var(--dwp2)*15.75) ;
                width:calc(var(--dwp2)*18) ;
            }
            .part2-table-right-last{
                background: #F5F6BB;
                display: flex;
                align-items: center;
                justify-content: center;
                width: calc(var(--dwp2)*136);
                }
                .part2-table-right-last p{
                    font-family: 'SkModernist-Bold';
                    font-weight: 700;
                    font-size:calc(var(--dwp2)*18);
                }
                .part2-table-right-last img{
                    height:calc(var(--dwp2)*15.75) ;
                    width:calc(var(--dwp2)*18) ;
                }
                .part2-table p {
                    display: flex;
                    height: 100%;
                    color:#184351;
                    align-items: flex-end;
                    font-family: 'SkModernist-Bold';
                    font-size: calc(var(--dwp2)*26);
                    line-height: calc(var(--dwp2)*31.2);
                    padding-bottom: calc(var(--dwp2)*12);

                }
    .packet-name{
        margin-top: calc(var(--dwp2)*35);
        font-family: 'SkModernist-Bold';
        font-weight: 700;
        font-size:calc(var(--dwp)*22);
        line-height: calc(var(--dwp2)*26.4);
        color:#272A27;
    }
    .packet-price{
        font-family: 'SkModernist-Bold';
        font-weight: 700;
        font-size:calc(var(--dwp2)*22);
        line-height: calc(var(--dwp2)*26.4);
        color:#272A27;

    }
    .packet-duration{
        font-family: 'SkModernist-Bold';
        font-weight: 700;
        font-size:calc(var(--dwp2)*17);
        line-height:calc(var(--dwp2)*20.4);
        
    }

    .packet-button{
        display: flex;
        width: calc(var(--dwp2)*120);
        height: calc(var(--dwp2)*41);
        margin-top:calc(var(--dwp2)*20) ;
        margin-bottom:calc(var(--dwp2)*12) ; 
        background:#9BE179 ;
        justify-content: center;
        align-items: center;
        box-shadow: 2px 2px 8px rgba(39, 42, 39, 0.23);
        border-radius: 14px;
    }
    .packet-button a{
        border-bottom: 0px solid  #272A27;;

        font-family: 'SkModernist-Bold';
        font-size:calc(var(--dwp2)*17);
        line-height: calc(var(--dwp2)*20.4);
        color:#184351
    }

    .packet-button2{
        display: flex;
        width: calc(var(--dwp2)*120);
        height: calc(var(--dwp2)*41);
        border-radius: 14px;
        margin-top:calc(var(--dwp2)*20) ;
        margin-bottom:calc(var(--dwp2)*12) ; 
        background:#272A27;
        justify-content: center;
        align-items: center;
        box-shadow: 2px 2px 8px rgba(39, 42, 39, 0.23);
    }
    .packet-button2 a{
        border-bottom: 0px solid  #272A27;;
        font-family: 'SkModernist-Regular';
        font-size:calc(var(--dwp2)*17);
        line-height: calc(var(--dwp2)*20.4);
        color:#8CC593
    }


    @media screen and (max-width: 500px) {
        .section7-container-m{
            background: url("../../assets/images/sec7bkgrnd\ \(1\).png");
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
        }

        .section7-title-m {
            display: flex;
            width: 100%;
            }

            .section7-title-m h5{
                font-family: 'SkModernist-Bold';
                font-size: calc(var(--dwpm)*34);
                line-height: calc(var(--dwpm)*40.8);
                color:#272A27;
                margin-left: calc(var(--dwpm)*22);
                margin-top: calc(var(--dhpm)*300);
                margin-bottom: calc(var(--dhpm)*123);
            }


            .section7-box1-m-container{
                display: flex;
            }   
            .section7-box1-m{
                position: relative;
                height: calc(var(--dwpm)*560);
                width: calc(var(--dwpm) * 346);
                background: url("../../assets/images/Sec7.1Bkgrnd.png");
                background-size: cover ;
                margin-bottom: calc(var(--dhpm)*400);

            }

            .section7-box1-m a{
                color:#184351;
                font-family: 'SkModernist-Bold';
                font-size: calc(var(--dwpm)*22);
                font-weight: 700;
                line-height: calc(var(--dwpm)*26.4);
                text-align: left;
                margin-bottom: calc(var(--dwpm)*12);
                
              
            
            }
            .section7-box1-m li{
                color: #272A27;
                font-family: 'SkModernist-Bold';
                font-size: calc(var(--dwpm)*17);
                font-weight: 400;
                line-height: calc(var(--dwpm)*20.4);
                text-align: left;
                list-style: inside;
                margin-bottom: calc(var(--dwpm)*8);
                margin-top: calc(var(--dwpm)*12);

            
            }
            .section7-box1-m-inner{
                padding-top: calc(var(--dwpm)*35);
                padding-right: calc(var(--dwpm)*22);
                padding-left: calc(var(--dwpm)*22);

            }
            .section7-box1-m-inner-dw{
                margin-top: calc(var(--dwpm)*35);

            }
            .section7-box2-m-container{
                display: flex;
            }   
            .section7-box2-m{
                position: relative;
                height: calc(var(--dwpm)*493);
                width: calc(var(--dwpm) * 346);
                background: url("../../assets/images/Rectangle\ 160.png");
                background-size: cover ;
                margin-bottom: calc(var(--dhpm)*57);

            }
            .section7-box2-m-pic1{
                position: absolute;
                height: calc(var(--dwpm)*462.72);
                width: calc(var(--dwpm) * 376.73);
                left:calc(var(--dwpm) * 15*-1);
                top:calc(var(--dwpm) * 260*-1)

            }
            .section7-box2-m-pic2{
                position: absolute;
                height: calc(var(--dwpm)*233.31);
                width: calc(var(--dwpm) * 302);
                left:calc(var(--dwpm) * 22);
                bottom: calc(var(--dwpm) * 100);
            }
            .learn-more-7{
                position: absolute;
                right: calc(var(--dwpm)*44);
                bottom: calc(var(--dwpm)*55);
                margin-top: calc(var(--dwpm)*35);
            }
            
            .learn-more-7 a {
                font-family:"SkModernist-Bold";
            font-size:calc(var(--dwpm)*20);
            font-weight: 700;
            line-height: calc(var(--dwpm)*24);
            letter-spacing: 0em;
            text-align: left;
            width: fit-content;
            border-bottom: 1px solid  #272A27;;
            background: url("../../assets/images/textTwoBack.png");
                background-attachment: center;
                background-size: cover;  
                background-clip: text;
                background-position:center;
                -webkit-background-clip: text;
                color: transparent;
            }
            .section7-logo-m-container{
                display: flex;
                width: 100%;
                justify-content: center;
                margin-bottom: calc(var(--dhpm)*57);

            }
            .section7-dill-logo-m{
                height: calc(var(--dwpm)*171);
                width: calc(var(--dwpm) * 149);
            }
        .section7-box-m-container{
            display: flex;
        }
        .section7-box-m{
            position: relative;
            height: calc(var(--dwpm)*1174);
            width: calc(var(--dwpm) * 346);
            border-radius: 41px;
            background:#F5F6BB;
            margin-bottom: calc(var(--dhpm)*199);
            box-shadow: 2px 2px 8px rgba(39, 42, 39, 0.23);
        }
        .section7-box-m-header{
            padding-top: calc(var(--dwpm)*35);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .packet-name{
            font-family: 'SkModernist-Bold';
            font-weight: 700;
            font-size:calc(var(--dwpm)*22);
            line-height: calc(var(--dwpm)*26.4);
            color:#272A27;
        }
        .packet-price{
            font-family: 'SkModernist-Bold';
            font-weight: 700;
            font-size:calc(var(--dwpm)*22);
            line-height: calc(var(--dwpm)*26.4);
            color:#272A27;
    
        }
        .packet-duration{
            font-family: 'SkModernist-Bold';
            font-weight: 700;
            font-size:calc(var(--dwpm)*17);
            line-height:calc(var(--dwpm)*20.4);
            color:#272A27;

        }
    
        .packet-button{
            display: flex;
            width: calc(var(--dwpm)*120);
            height: calc(var(--dwpm)*41);
            margin-top:calc(var(--dwpm)*20) ;
            margin-bottom:calc(var(--dwpm)*12) ; 
            background:#9BE179 ;
            justify-content: center;
            align-items: center;
            box-shadow: 2px 2px 8px rgba(39, 42, 39, 0.23);
            border-radius: 14px;
        }
        .packet-button a{
            font-family: 'SkModernist-Bold';
            font-size:calc(var(--dwpm)*17);
            line-height: calc(var(--dwpm)*20.4);
            color:#184351
        }
        .packet-button2{
            display: flex;
            width: calc(var(--dwpm)*120);
            height: calc(var(--dwpm)*41);
            margin-top:calc(var(--dwpm)*20) ;
            margin-bottom:calc(var(--dwpm)*12) ; 
            background:#272A27 ;
            justify-content: center;
            align-items: center;
            box-shadow: 2px 2px 8px rgba(39, 42, 39, 0.23);
            border-radius: 14px;
        }
        .packet-button2 a{
            font-family: 'SkModernist-Bold';
            font-size:calc(var(--dwpm)*17);
            line-height: calc(var(--dwpm)*20.4);
            color:#8CC593
        }
       
        .section7-box-m-body{
            display: flex;
            flex-direction: column;
            padding-left: calc(var(--dwpm)*22);
            padding-right: calc(var(--dwpm)*22);
        }
        .section7-box-m-body-1{
            display: flex;
            flex-direction: row;
            width: 100%;
            border-top: 1px solid  #272A27;;
            padding-top: calc(var(--dwpm)*12);
            padding-bottom: calc(var(--dwpm)*12);
            color: #272A27;

        }
        .section7-box-m-body-1-w{
            display: flex;
            flex-direction: row;
            width: 100%;
            border-top: 1px solid  #272A27;;
            padding-top: calc(var(--dwpm)*12);
            padding-bottom: calc(var(--dwpm)*12);
        }

        .section7-box-m-body-1-w h4{
            font-family: 'SkModernist-Bold';
            font-size: calc(var(--dwpm)*22);
            font-weight: 400;
            line-height:calc(var(--dwpm)*26);
            letter-spacing: 0em;
            text-align: left;
            width: calc(var(--dwpm)*260);
            color:#272A27


        }

        .section7-box-m-body-1 h4{
            font-family: 'SkModernist-Regular';
            font-size: calc(var(--dwpm)*22);
            font-weight: 400;
            line-height:calc(var(--dwpm)*26);
            letter-spacing: 0em;
            text-align: left;

        }
        .section7-box-m-body-1 a{
            position: absolute;
            right: calc(var(--dwpm)*22);
            font-family: 'SkModernist-Bold';
            font-size: calc(var(--dwpm)*26);
            font-weight: 700;
            line-height: calc(var(--dwpm)*31);
            letter-spacing: 0em;
            text-align: center;

        }
        .section7-box-m-body-2{
            display: flex;
            flex-direction: column;
            width: 100%;
            border-top: 1px solid  #272A27;;
            padding-top: calc(var(--dwpm)*12);
            padding-bottom: calc(var(--dwpm)*12);

        }
        .section7-box-m-body-2-child{
            display: flex;
            flex-direction: row;
            width: 100%;
           
            padding-top: calc(var(--dwpm)*6);
            padding-bottom: calc(var(--dwpm)*6);

        }
        .section7-box-m-body-2-child-inner{
            display: flex;
            flex-direction: row;
            width: 100%;
            padding-top: calc(var(--dwpm)*6);
            padding-bottom: calc(var(--dwpm)*6);

        }

        .section7-box-m-body-2 h4{
            font-family: 'SkModernist-Regular';
            font-size: calc(var(--dwpm)*22);
            font-weight: 400;
            line-height:calc(var(--dwpm)*26);
            letter-spacing: 0em;
            text-align: left;
            color:#272A27


        }
        .section7-box-m-body-2-child li{
            font-family: 'SkModernist-Regular';
            font-size: calc(var(--dwpm)*17);
            font-weight: 400;
            line-height: calc(var(--dwpm)*20);
            letter-spacing: 0em;
            text-align: left;
            list-style: inside;
            width: calc(var(--dwpm)*260);
            color:#272A27
        }
        .section7-box-m-body-2-child a{
            color:#272A27;

            font-family: 'SkModernist-Bold';
            font-size: calc(var(--dwpm)*34);
            font-weight: 400;
            line-height: calc(var(--dwpm)*40.8);
            letter-spacing: 0em;
            text-align: left;
            list-style: inside;
            width: calc(var(--dwpm)*260);
        }
        .section7-box-m-body-2-child-inner li{
            font-family: 'SkModernist-Regular';
            font-size: calc(var(--dwpm)*17);
            font-weight: 400;
            line-height: calc(var(--dwpm)*20);
            letter-spacing: 0em;
            text-align: left;
            list-style: inside;
            width: calc(var(--dwpm)*230);
            margin-left:calc(var(--dwpm)*30) ;
            color:#272A27

        }
        .tick-container{
            position: relative;
            display: flex;
            align-items: center;
        }
        .tick-container img{
            position: relative;
            display: flex;
            padding-left: calc(var(--dwpm)*22);
        }



        .section7-box4-m-container{
            display: flex;
        }
        .section7-box4-m{
            position: relative;
            height: calc(var(--dwpm)*1240);
            width: calc(var(--dwpm) * 346);
            border-radius: 41px;
            background:#C7EDFE;
            margin-bottom: calc(var(--dhpm)*199);
            box-shadow: 2px 2px 8px rgba(39, 42, 39, 0.23);
        }
        .section7-box4-m-header{
            padding-top: calc(var(--dwpm)*35);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .section7-box5-m-container{
            display: flex;
        }
        .section7-box5-m{
            position: relative;
            height: calc(var(--dwpm)*530);
            width: calc(var(--dwpm) * 346);
            border-radius: 41px;
            background:#A7BADB;
            margin-bottom: calc(var(--dhpm)*199);
            box-shadow: 2px 2px 8px rgba(39, 42, 39, 0.23);
        }

        .section7-box6-m-container{
            display: flex;
        }
        .section7-box6-m{
            position: relative;
            height: calc(var(--dwpm)*510);
            width: calc(var(--dwpm) * 346);
            border-radius: 41px;
            background:#FFB9B9;
            margin-bottom: calc(var(--dhpm)*199);
            box-shadow: 2px 2px 8px rgba(39, 42, 39, 0.23);
        }
    }