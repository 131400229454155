:root{
    --dwp:calc(100vw/1512);
    --bckgr:rgba(39, 42, 39, 0.77);
    --blry: blur(80px)
  }


.section3-container{
    position: relative;
    height: calc(var(--dfx)*657);
   display: flex;
   background: #C7EDFE;
   justify-content: center;
   align-items: center;
   z-index: 99;
}
.section3-blurry{
    height: calc(var(--dfx)*657);
    display: flex;
    z-index: 99;


}
.section3-container2{
    height: calc(var(--dfx)*657);
    display: flex;
    justify-content: center;
    align-items: center;
}
.section3-logo{
    position: absolute;
    height: calc(var(--dfx) * 375);
    z-index: 2;

}
.section3-cloud{
    width: calc(var(--dwp)*2713);
    z-index: 2;

}
.slideClody {
    -webkit-animation: slideIn3 2s forwards;
    -moz-animation: slideIn3 2s forwards;
    animation: slideIn3 2s forwards;
  }
  .cloudyDill{
    right: 0;
    z-index: 1;
    position: absolute;
}
  @-webkit-keyframes slideIn3{
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(120vw);
    }
  }
  @-moz-keyframes slideIn3 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(120vw);
    }
  }
  @keyframes slideIn3 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(120vw);
    }
  }

  .blurryBack{
    -webkit-animation: blurry 1.5s forwards;
    -moz-animation:blurry 1.5s forwards;
    animation: blurry 1.5s forwards;
  }

  @-webkit-keyframes blurry{
    0% {
        background-color: rgba(39, 42, 39, 0.77);  
        backdrop-filter: blur(80px);
    }
    100% {
        background-color: transparent;
        backdrop-filter: blur(0px);

    }
  }
  @-moz-keyframes blurry {
    0% {
        background-color: rgba(39, 42, 39, 0.77);  
        backdrop-filter: blur(80px);

    }
    100% {
        background-color: transparent;
        backdrop-filter: blur(0px);

    }
  }
  @keyframes blurry{
    0% {
        background-color: rgba(39, 42, 39, 0.77);  
        backdrop-filter: blur(80px);

    }
    100% {
        background-color: transparent;
        backdrop-filter: blur(0px);

    }
  }