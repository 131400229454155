:root{
    --dwp:calc(100vw/1512);
    --bckgr:rgba(39, 42, 39, 0.77);
    --blry: blur(80px)
  }


.section3t4-container{
position: relative;
display: flex;
height: calc(var(--dfx)*657);
background:url("../../assets/images/Rectangle\ 140.png");
background-size: cover;

}

.mockup1{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom:calc(var(--dwp)*(20));
    left:calc(var(--dwp)*(-260));
    overflow: hidden;
}
.mockup2{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(-200));
    left:calc(var(--dwp)*(-180));
    overflow: hidden;
}
.mockup3{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(310));
    left:calc(var(--dwp)*(-180));
    overflow: hidden;
}

.mockup4{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(240));
    left:calc(var(--dwp)*(200));
    overflow: hidden;
}

.mockup5{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(10));
    left:calc(var(--dwp)*(300));
    overflow: hidden;
}

.mockup6{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(230));
    left:calc(var(--dwp)*(760));
    overflow: hidden;
}
.mockup7{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(-160));
    right: calc(var(--dwp)*(360));
    overflow: hidden;
}
.mockup8{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(60));
    right: calc(var(--dwp)*(-120));
    overflow: hidden;
}
.mockup9{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(-180));
    right: calc(var(--dwp)*(-120));
    overflow: hidden;
}
.mockup10{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(-380));
    right: calc(var(--dwp)*(820));
    overflow: hidden;
}
.mockup11{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(-400));
    right: calc(var(--dwp)*(350));
    overflow: hidden;
}
.mockup12{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom:calc(var(--dwp)*(90));
    left:calc(var(--dwp)*(-650));
    overflow: hidden;
}
.mockup13{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(540));
    left:calc(var(--dwp)*(280));
    overflow: hidden;
}
.mockup14{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(460));
    left:calc(var(--dwp)*(670));
    overflow: hidden;
}
.mockup15{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(470));
    left:calc(var(--dwp)*(-400));
    overflow: hidden;
}
.mockup16{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(680));
    left:calc(var(--dwp)*(60));
    overflow: hidden;
}
.mockup17{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(780));
    left:calc(var(--dwp)*(750));
    overflow: hidden;
}
.mockup18{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(-420));
    right: calc(var(--dwp)*(1500));
    overflow: hidden;
}
.mockup19{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(-200));
    right: calc(var(--dwp)*(1570));
    overflow: hidden;
}
.mockup20{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(450));
    left:calc(var(--dwp)*(1220));
    overflow: hidden;
}
.mockup21{
    position: absolute;
    z-index: 0;
    height:calc(var(--dwp)*417) ;
    width:calc(var(--dwp)*669) ;
    bottom: calc(var(--dwp)*(680));
    left:calc(var(--dwp)*(1130));
    overflow: hidden;
}
.mockupAnimation {
    animation-fill-mode: both;
  backface-visibility: hidden;
    -webkit-animation: mockupMove 5s infinite linear;
    -moz-backface-visibility: hidden;
    -moz-animation: mockupMove 5s infinite  linear;
    animation: mockupMove 5s infinite  linear;
   
  }


  @-webkit-keyframes mockupMove{
    50% {
        transform: translate3D(200px, -200px, 0);
      }
  }


  @-moz-keyframes mockupMove {
    50% {
        transform: translate3D(200px, -200px, 0);
      }
  }

  @keyframes mockupMove {
    50% {
        transform: translate3D(200px, -200px, 0);
      }
  }
  @media screen and (max-width: 500px) {
    .mockupAnimation {
        animation-fill-mode: both;
      backface-visibility: hidden;
        -webkit-animation: mockupMove 3s infinite linear;
        -moz-backface-visibility: hidden;
        -moz-animation: mockupMove 3s infinite  linear;
        animation: mockupMove 3s infinite  linear;
       
      }
    
    
      @-webkit-keyframes mockupMove{
        50% {
            transform: translate3D(50px, -50px, 0);
          }
      }
    
    
      @-moz-keyframes mockupMove {
        50% {
            transform: translate3D(50px, -50px, 0);
          }
      }
    
      @keyframes mockupMove {
        50% {
            transform: translate3D(50px, -50px, 0);
          }
      }

  }