:root{
    --dwp:calc(100vw/1512);
    --dwpm:calc(100vw/390);
    --dhpm:calc(100vh/844);
}

.section5-container{
    position: relative;
    background: url("../../assets/images/Rectangle\ 125.png");
    background-size: cover;
    flex-direction: column;
    display: flex;
}
.section5to6{
    position: absolute;
    bottom: calc((var(--dwp)*522*-1));
}
.section4to5{
    display: flex;
    }
.section5-main-container{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    align-items: center;
}
.section5-main-subcontainer{
display: flex;
flex-direction: column;
}
.section5-part1{
    margin-top: calc(var(--dwp)*777);
    display: flex;
    flex-direction: row;
    width: 100%;
}
.section5-part1-left{
    width:calc(var(--dwp2)*578) ;
    display: flex;
    flex-direction: column;
    margin-left: calc(var(--dwp2)*180);
}
.section5-part1-left li {
    font-family: 'SkModernist-Regular';
    list-style: inside;
    font-size: calc(var(--dwp2)*22);
    line-height: calc(var(--dwp2)*26.4);
    color:#272A27
}
.section5-part1-left h4{
    width: fit-content;
    border-bottom: 1px solid black;
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
    font-family: 'SkModernist-Bold';
    font-size:calc(var(--dwp2)*34);
    line-height: calc(var(--dwp2)*40.8);
    font-weight: 700;
    margin-bottom:calc(var(--dwp2)*41);
}
.section5-part1-left h5{
    font-family: 'SkModernist-Regular';
    font-size:calc(var(--dwp2)*34);
    line-height:calc(var(--dwp2)*40.8);
    font-weight: 400;
    margin-bottom:calc(var(--dwp2)*12);
    color:#184351
   
}
.section5-part1-left a{
    font-family: 'SkModernist-Bold';
    font-size: calc(var(--dwp2)*20);
    line-height:calc(var(--dwp2)*24);
    font-weight: 400;
    margin-top: calc(var(--dwp2)*29);
    margin-bottom:calc(var(--dwp2)*34);
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
    border-bottom: 1px solid black;
    
   
}
.section5-image1{
    margin-left: calc(var(--dwp2)*136);
    margin-right: calc(var(--dwp)*180);
    margin-top: calc(var(--dwp)*221);
    margin-bottom: calc(var(--dwp)*136);
    height: calc(var(--dwp2)*441);
    width: calc(var(--dwp2)*441);
}
.section5-scheduleAp{
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
}

.section5-part2{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.section5-part2-left{
    display: flex;
    flex-direction: column;
    
}
.section5-part2-right{
    width:calc(var(--dwp2)*578) ;
    display: flex;
    flex-direction: column;
    margin-left: calc(var(--dwp2)*60);
    margin-right:calc(var(--dwp2)*136) ;
}
.section5-part2-right h4{
    width: fit-content;
    border-bottom: 1px solid black;
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
    font-family: 'SkModernist-Bold';
    font-size: calc(var(--dwp2)*34);
    line-height:calc(var(--dwp2)*40.8);
    font-weight: 700;
    margin-bottom: calc(var(--dwp2)*41);
    margin-top:calc(var(--dwp2)*60);

}
.section5-part2-right h5{
    font-family: 'SkModernist-Regular';
    font-size: calc(var(--dwp2)*34);
    line-height:calc(var(--dwp2)*40.8);
    font-weight: 400;
    margin-bottom: calc(var(--dwp2)*12);
    color:#184351;
}
.section5-part2-right li {
    font-family: 'SkModernist-Regular';
    list-style: inside;
    font-size: calc(var(--dwp2)*22);
    line-height:calc(var(--dwp2)*26.4);
    color:#272A27
}
.section5-image2{
    margin-left: calc(var(--dwp)*136);
    margin-bottom:calc(var(--dwp)*221) ;
    height: calc(var(--dwp2)*383);
    width: calc(var(--dwp2)*571);
}


.section5-part3{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.section5-part3-left{
    width:calc(var(--dwp2)*578) ;
    display: flex;
    flex-direction: column;
    margin-left: calc(var(--dwp2)*180);
}
.section5-part3-left li {
    font-family: 'SkModernist-Regular';
    list-style: inside;
    font-size:calc(var(--dwp2)*22);
    line-height: calc(var(--dwp2)*26.4);
    color:#272A27
}
.section5-part3-left h4{
    width: fit-content;
    border-bottom: 1px solid black;
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
    font-family: 'SkModernist-Bold';
    font-size: calc(var(--dwp2)*34);
    line-height:calc(var(--dwp2)*40.8);
    font-weight: 700;
    margin-bottom: calc(var(--dwp2)*41);
}
.section5-part3-left h5{
    font-family: 'SkModernist-Regular';
    font-size: calc(var(--dwp2)*34);
    line-height:calc(var(--dwp2)*40.8);
    font-weight: 400;
    margin-bottom: calc(var(--dwp2)*12);
    color:#184351
   
}
.section5-part3-left a{
    font-family: 'SkModernist-Bold';
    font-size: calc(var(--dwp2)*20);
    line-height:calc(var(--dwp2)*24);
    font-weight: 400;
    margin-top: calc(var(--dwp2)*29);
    margin-bottom:calc(var(--dwp2)*34);
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
    border-bottom: 1px solid black;
    
   
}
.section5-image3{
    margin-top:calc(var(--dwp2)*84);
    margin-bottom:calc(var(--dwp2)*52);
    margin-left: calc(var(--dwp2)*137);
    margin-right: calc(var(--dwp)*150) ;
    height: calc(var(--dwp2)*463);
    width: calc(var(--dwp2)*468);
}


.section5-part4{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.section5-part4-left{
    display: flex;
    flex-direction: column;
    
}
.section5-part4-right{
    width:calc(var(--dwp2)*578) ;
    display: flex;
    flex-direction: column;
    margin-top: calc(var(--dwp2)*86);
    margin-right:calc(var(--dwp2)*136) ;
    margin-bottom:calc(var(--dwp2)*357) ;
}
.section5-part4-right h4{
    width: fit-content;
    border-bottom: 1px solid black;
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
    font-family: 'SkModernist-Bold';
    font-size:calc(var(--dwp2)*34);
    line-height:calc(var(--dwp2)*40.8);
    font-weight: 700;
    margin-bottom:calc(var(--dwp2)*41);
    margin-top: calc(var(--dwp2)*60);

}
.section5-part4-right h5{
    font-family: 'SkModernist-Regular';
    font-size: calc(var(--dwp2)*34);
    line-height:calc(var(--dwp2)*40.8);
    font-weight: 400;
    margin-bottom: calc(var(--dwp2)*12);
    color:#184351;
}
.section5-part4-right li {
    font-family: 'SkModernist-Regular';
    list-style: inside;
    font-size:calc(var(--dwp2)*22);
    line-height: calc(var(--dwp2)*26.4);
    color:#272A27
}
.section5-image4{
    margin-left: calc(var(--dwp2)*180);
    margin-right:calc(var(--dwp2)*136) ;
    height: calc(var(--dwp2)*315);
    width: calc(var(--dwp2)*439);
}


.section5-main-container li{
    margin-bottom: calc(var(--dwp2)*10)
}

.section5-part5{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: calc(var(--dwp)*869);
}
.section5-part5-left{
    width:calc(var(--dwp2)*578) ;
    display: flex;
    flex-direction: column;
    margin-left: calc(var(--dwp2)*180);
}
.section5-part5-left li {
    font-family: 'SkModernist-Regular';
    list-style: inside;
    font-size:calc(var(--dwp2)*22);
    line-height: calc(var(--dwp2)*26.4);
    color:#272A27
}
.section5-part5-left h4{
    width: fit-content;
    border-bottom: 1px solid black;
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
    font-family: 'SkModernist-Bold';
    font-size: calc(var(--dwp2)*34);
    line-height: calc(var(--dwp2)*40.8);
    font-weight: 700;
    margin-bottom: calc(var(--dwp2)*41);
}
.section5-part5-left h5{
    font-family: 'SkModernist-Regular';
    font-size: calc(var(--dwp2)*34);
    line-height: calc(var(--dwp2)*40.8);
    font-weight: 400;
    margin-bottom: calc(var(--dwp2)*12);
    color:#184351
   
}
.section5-part5-left a{
    font-family: 'SkModernist-Bold';
    font-size: calc(var(--dwp2)*20);
    line-height:calc(var(--dwp2)*24);
    font-weight: 400;
    margin-top: calc(var(--dwp2)*29);
    margin-bottom:calc(var(--dwp2)*34);
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
    border-bottom: 1px solid black;
    
   
}

.section5-image5{

    margin-left: calc(var(--dwp2)*42);
    margin-right: calc(var(--dwp2)*146) ;
    height: calc(var(--dwp2)*475);
    width: calc(var(--dwp2)*564);
}


@media screen and (max-width: 500px) {
    .section5-container{
        position: relative;
        background: url("../../assets/images/Rectangle\ 125.png");
        background-size: cover;
        flex-direction: column;
        display: flex;
    }
    .section5-m-title{
        display: block;
        position: relative;
        width: 100%;
    }
    .section5-gap{
        height: calc((var(--dwpm)*340));
        width: 100%;
    }
    .section5-m-title h5{
        font-family: 'SkModernist-Bold';
        text-transform: none;
        font-size:calc(var(--dwpm)*34);
        color:rgba(24, 67, 81, 1) ;
        margin-left:calc(var(--dwpm)*22) ;
        padding-top: calc(var(--dwpm)*323);
    }
    .section5to6{
        position: absolute;
        bottom: calc((var(--dwp)*462*-1));
    }
    .section4to5{
        display: flex;
        }
    .section5-main-container{
        position: relative;
        width: 100%;
    }
    .section5-part1{
        margin-top: calc(var(--dhpm)*76);

        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .section5-part1-left{
        position: relative;
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-left: calc(var(--dwpm)*22);
        margin-right: calc(var(--dwpm)*22);
        margin-bottom: calc(var(--dhpm)*221);

    }
    .section5-part1-left li {
        position: relative;
        font-family: 'SkModernist-Regular';
        list-style: inside;
        font-size: calc(var(--dwpm)*17);
        line-height: calc(var(--dwpm)*20.4);
        margin-bottom: calc(var(--dwpm)*10);
        color:#272A27;
        z-index: 9;
    }
    .section5-part1-left h4{
        width: fit-content;
        border-bottom: 1px solid black;
        background: url("../../assets/images/textTwoBack.png");
        background-attachment: center;
        background-size: cover;  
        background-clip: text;
        background-position:center;
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'SkModernist-Bold';
        font-size:calc(var(--dwpm)*26);
        line-height: calc(var(--dwpm)*31.2);
        font-weight: 700;
        margin-bottom:calc(var(--dhpm)*35);
    }
    .section5-part1-left h5{
        font-family: 'SkModernist-Regular';
        font-size:calc(var(--dwpm)*22);
        line-height:calc(var(--dwpm)*26.4);
        font-weight: 400;
        margin-bottom:calc(var(--dwpm)*12);
        color:#184351
       
    }
    .section5-part1-left a{
        font-family: 'SkModernist-Bold';
        font-size: calc(var(--dwpm)*20);
        line-height:calc(var(--dwpm)*24);
        font-weight: 400;
        margin-top: calc(var(--dhpm)*35);
        margin-bottom:calc(var(--dhpm)*35);
        background: url("../../assets/images/textTwoBack.png");
        background-attachment: center;
        background-size: cover;  
        background-clip: text;
        background-position:center;
        -webkit-background-clip: text;
        color: transparent;
        border-bottom: 1px solid black;
        
       
    }
    .section5-image1{
        position: absolute;
        z-index: 1;
        right: calc(var(--dwpm)*41*-1);
        bottom:calc(var(--dwpm)*261*-1);
        height: calc(var(--dwpm)*241);
        width: calc(var(--dwpm)*241);
    }
    .section5-scheduleAp{
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
    }
    
    .section5-part2{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .section5-part2-left{
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: calc(var(--dhpm)*200);
   
    }
    .section5-part2-right{
        position: relative;
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-left: calc(var(--dwpm)*22);
        margin-right: calc(var(--dwpm)*22);
        margin-bottom: calc(var(--dhpm)*22);
    }
    .section5-part2-right h4{
        width: fit-content;
        border-bottom: 1px solid black;
        background: url("../../assets/images/textTwoBack.png");
        background-attachment: center;
        background-size: cover;  
        background-clip: text;
        background-position:center;
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'SkModernist-Bold';
        font-size:calc(var(--dwpm)*26);
        line-height: calc(var(--dwpm)*31.2);
        font-weight: 700;
        margin-bottom:calc(var(--dhpm)*35);
    
    }
    .section5-part2-right h5{
        font-family: 'SkModernist-Regular';
        font-size:calc(var(--dwpm)*22);
        line-height:calc(var(--dwpm)*26.4);
        font-weight: 400;
        margin-bottom:calc(var(--dwpm)*12);
        color:#184351
    }
    .section5-part2-right li {
        position: relative;
        font-family: 'SkModernist-Regular';
        list-style: inside;
        font-size: calc(var(--dwpm)*17);
        line-height: calc(var(--dwpm)*20.4);
        margin-bottom: calc(var(--dwpm)*10);
        color:#272A27;
        z-index: 9;
    }
    .section5-image2{
        position:absolute;
        right: 0;
        height: calc(var(--dwpm)*152);
        width: calc(var(--dwpm)*315);
    }
    
    
    .section5-part3{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .section5-part3-right{
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: calc(var(--dhpm)*70);
   
    }
    .section5-part3-left{
        position: relative;
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-left: calc(var(--dwpm)*22);
        margin-right: calc(var(--dwpm)*22);
    }
    .section5-part3-left li {
        position: relative;
        font-family: 'SkModernist-Regular';
        list-style: inside;
        font-size: calc(var(--dwpm)*17);
        line-height: calc(var(--dwpm)*20.4);
        margin-bottom: calc(var(--dwpm)*10);
        color:#272A27;
        z-index: 9;
    }
    .section5-part3-left h4{
        width: fit-content;
        border-bottom: 1px solid black;
        background: url("../../assets/images/textTwoBack.png");
        background-attachment: center;
        background-size: cover;  
        background-clip: text;
        background-position:center;
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'SkModernist-Bold';
        font-size:calc(var(--dwpm)*26);
        line-height: calc(var(--dwpm)*31.2);
        font-weight: 700;
        margin-bottom:calc(var(--dhpm)*35);
    }
    .section5-part3-left h5{
        font-family: 'SkModernist-Regular';
        font-size:calc(var(--dwpm)*22);
        line-height:calc(var(--dwpm)*26.4);
        font-weight: 400;
        margin-bottom:calc(var(--dwpm)*12);
        color:#184351
       
    }

    .section5-image3{
    top:calc(var(--dwpm)*-149);
    right: calc(var(--dwpm)*-47);
    position: absolute;
    height: calc(var(--dwpm)*322);
    width: calc(var(--dwpm)*199);

    }
    
    


    .section5-part4{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .section5-part4-left{
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: calc(var(--dhpm)*140);

    }
    .section5-part4-right{
        position: relative;
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-left: calc(var(--dwpm)*22);
        margin-right: calc(var(--dwpm)*22);
    }
    .section5-part4-right h4{
        width: fit-content;
        border-bottom: 1px solid black;
        background: url("../../assets/images/textTwoBack.png");
        background-attachment: center;
        background-size: cover;  
        background-clip: text;
        background-position:center;
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'SkModernist-Bold';
        font-size:calc(var(--dwpm)*26);
        line-height: calc(var(--dwpm)*31.2);
        font-weight: 700;
        margin-bottom:calc(var(--dhpm)*35);
    
    }
    .section5-part4-right h5{
        font-family: 'SkModernist-Regular';
        font-size:calc(var(--dwpm)*22);
        line-height:calc(var(--dwpm)*26.4);
        font-weight: 400;
        margin-bottom:calc(var(--dwpm)*12);
        color:#184351
    }
    .section5-part4-right li {
        position: relative;
        font-family: 'SkModernist-Regular';
        list-style: inside;
        font-size: calc(var(--dwpm)*17);
        line-height: calc(var(--dwpm)*20.4);
        margin-bottom: calc(var(--dwpm)*10);
        color:#272A27;
        z-index: 9;
    }
    .section5-image4{
        top: calc(var(--dwpm)*-112);
        right: calc(var(--dwpm)*-47);

        position: absolute;
        height: calc(var(--dwpm)*224);
        width: calc(var(--dwpm)*317);
    }
    
    
    .section5-part5{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: calc(var(--dwp)*1012);
    }
    .section5-part5-left{
        position: relative;
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-left: calc(var(--dwpm)*22);
        margin-right: calc(var(--dwpm)*22);
    }
    .section5-part5-left li {
        position: relative;
        font-family: 'SkModernist-Regular';
        list-style: inside;
        font-size: calc(var(--dwpm)*17);
        line-height: calc(var(--dwpm)*20.4);
        margin-bottom: calc(var(--dwpm)*10);
        color:#272A27;
        z-index: 9;
    }
    .section5-part5-left h4{
        width: fit-content;
        border-bottom: 1px solid black;
        background: url("../../assets/images/textTwoBack.png");
        background-attachment: center;
        background-size: cover;  
        background-clip: text;
        background-position:center;
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'SkModernist-Bold';
        font-size:calc(var(--dwpm)*26);
        line-height: calc(var(--dwpm)*31.2);
        font-weight: 700;
        margin-bottom:calc(var(--dhpm)*35);
    }
    .section5-part5-left h5{
        font-family: 'SkModernist-Regular';
        font-size:calc(var(--dwpm)*22);
        line-height:calc(var(--dwpm)*26.4);
        font-weight: 400;
        margin-bottom:calc(var(--dwpm)*12);
        color:#184351
       
    }

    .section5-part5-right{
        position: relative;
        display: flex;
     
        flex-direction: column;
    }
    .section5-image5{
        margin-top: calc(var(--dhpm)*47);
        margin-left: calc(var(--dwpm)*36);
        margin-right: calc(var(--dwpm)*76);
        display: flex;
        position: relative;
        height: calc(var(--dwpm)*336);
        width: calc(var(--dwpm)*341);
    }
    .section4to5part{
        position: absolute;
        top:calc(var(--dhpm)*350*-1)
    }
}