
:root{
      --dwp2:calc(100vw/1512);
    --dwp:calc(100vw/1512);
    --dwpm:calc(100vw/390);
    --dhpm:calc(100vh/844);
}


.cloud1{
    height:calc(var(--dwp)*766.67) ;
    width: calc(var(--dwp)*1616)  ;
    right: 0;
    z-index: -99;
    position: absolute;
    top:5%
}

.cloud2{
    height:calc(var(--dwp)*596.73) ;
    width: calc(var(--dwp)*1048)  ;
    z-index: -99;
    position: absolute;
    top:25%;
    right:10%
}
.cloud3{
    height:calc(var(--dwp)*591.54) ;
    width: calc(var(--dwp)*1248)  ;
    z-index: -99;
    position: absolute;
    top:35%;
    left:0
}
.cloud4{
    height:calc(var(--dwp)*652.79) ;
    width: calc(var(--dwp)*1323)  ;
    z-index: -99;
    position: absolute;
    top:50%;
    right: 0;
}
.cloud5{
    height:calc(var(--dwp)*924.93) ;
    width: calc(var(--dwp)*1337)  ;
    z-index: -99;
    position: absolute;
    top:60%
}
.cloud6{
    height:calc(var(--dwp)*1359.85) ;
    width: calc(var(--dwp)*2731)  ;
    z-index: -99;
    position: absolute;
    bottom:0
}

 .feature-list{
    margin-left: calc(var(--dwp)*267);
    margin-right: calc(var(--dwp)*267);
    display: flex;
    flex-direction: column;

 }
.section-features {
    position: relative;
    background:url("../../assets/images/blue\ 1\ \(1\).png");
    background-size: cover;
    flex-direction: column;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
}

.section-features-subcontainer{
    display: flex;
    background: linear-gradient(rgba(176, 187, 211, 0.38), rgba(185, 188, 185, 0.62), rgba(39, 42, 39, 0.77));
    backdrop-filter: blur(10px);
      position: relative;
      flex-direction: column;

    
}
.section-features-subcontainer2{
   display: flex;
    
}
.section-features-subcontainer h3{
    text-transform: none;
    font-size: calc(var(--dwp2)*41);
    margin-top: calc(var(--dwp)*180);
    margin-bottom:calc(var(--dwp)*180) ;
    margin-left: calc(var(--dwp)*136);
    color: #272A27;
    font-family: "SkModernist-Bold";

}
.featuresContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: calc(var(--dwp)*25);
    margin-left:  calc(var(--dwp) * 60);

}
.featuresContainer h4{
    font-family: "SkModernist-Bold";
    font-size: calc(var(--dwp2)*34) ;
    line-height:calc(var(--dwp2)*40.8);
    text-transform: none;
    color: #F9F3F3;
    font-weight: 700;
    margin-bottom: calc(var(--dwp)*16);

  }
.featuresContainer li {
    font-family: "SkModernist-Regular";
    font-size: calc(var(--dwp2)*22) ;
    text-transform: none;
    color: #F9F3F3;
    font-weight: 400;
    margin-left: 5%;
    margin-bottom: calc(var(--dwp)*12);
  }

.featuresContainer2 {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: calc(var(--dwp)*25);
  margin-left:  calc(var(--dwp) * 60);
}

.featuresContainer2 h4{
  font-family: "SkModernist-Bold";
  font-size: calc(var(--dwp2)*34) ;
  line-height:calc(var(--dwp2)*40.8);
  text-transform: none;
  color: #272A27;
  font-weight: 700;
  margin-bottom: calc(var(--dwp)*16);

}
.featuresContainer2 li {
  font-family: "SkModernist-Regular";
  font-size: calc(var(--dwp2)*22) ;
  text-transform: none;
  color: #272A27;
  font-weight: 400;
  margin-left: 5%;
  margin-bottom: calc(var(--dwp)*12);
}


.headerTitle{
    display: flex;
    flex-direction: row;
}


.mainFeatures-Container{
    display: flex;
    margin-bottom:calc(var(--dwp)*180);
    flex-direction: row;
}
.iconPart{
    display: flex;
    flex-direction: column;
    height:calc(var(--dwp2)*180);
    width:calc(var(--dwp2)*180);
    border-radius: 8px;
    background:url("../../assets/images/sec2IconBckgrnd.png");
    background-size: cover;
    justify-content: center;
    align-items: center;
}

.section2-icon1{
    height:calc(var(--dwp2)*112) ;
    width:calc(var(--dwp2)*110) ;
}
.section2-icon2{
   
    height:calc(var(--dwp2)*107);
    width: calc(var(--dwp2)*115);
}
.section2-icon3{
  
    height:calc(var(--dwp2)*97)  ;
    width:calc(var(--dwp2)*125) ;
}
.section2-icon4{

    height:calc(var(--dwp2)*120) ;
    width:calc(var(--dwp2)*102);
}
.section2-icon5{

    height:calc(var(--dwp2)*111) ;
    width:calc(var(--dwp2)*111) ;
}
.section2-icon6{

    height: calc(var(--dwp2)*121);
    width: calc(var(--dwp2)*101);
}


  
  .slide {
    -webkit-animation: slideIn 1.5s forwards;
    -moz-animation: slideIn 1.5s forwards;
    animation: slideIn 1.5s forwards;
  }
  
  @-webkit-keyframes slideIn {
    0% {
      transform: translateX(calc(var(--dwp)*(-900)));
    }
    100% {
      transform: translateX(0);
    }
  }
  @-moz-keyframes slideIn {
    0% {
        transform: translateX(calc(var(--dwp)*(-900)));    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideIn {
    0% {
        transform: translateX(calc(var(--dwp)*(-900)));    }
    100% {
      transform: translateX(0);
    }
  }

  .slide2 {
    -webkit-animation: slideIn2 1.5s forwards;
    -moz-animation: slideIn2 1.5s forwards;
    animation: slideIn2 1.5s forwards;
  }
  
  @-webkit-keyframes slideIn2 {
    0% {
        transform: translateX(calc(var(--dwp)*(900)));    }
    100% {
      transform: translateX(0);
    }
  }
  @-moz-keyframes slideIn2 {
    0% {
        transform: translateX(calc(var(--dwp)*(900)));    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideIn2 {
    0% {
        transform: translateX(calc(var(--dwp)*(900)));    }
    100% {
      transform: translateX(0);
    }
  }

  @media screen and (max-width: 500px) {
    .feature-list{
    margin-left: calc(var(--dwpm)*22);
    margin-right: calc(var(--dwpm)*30);
    display: flex;
    flex-direction: column;

 }
.section-features {
    background:url("../../assets/images/blue\ 1\ \(1\).png");
    background-size: cover;
    flex-direction: column;
    backdrop-filter: blur(10px);
    display: flex;
}

.section-features-subcontainer{
    background: linear-gradient(rgba(176, 187, 211, 0.38), rgba(185, 188, 185, 0.62), rgba(39, 42, 39, 0.77));
    backdrop-filter: blur(10px);
      position: relative;
    
}
.section-features-subcontainer h3{
    text-transform: none;
    font-size: calc(var(--dwpm)*34);
    margin-top: calc(var(--dhpm)*123);
    margin-bottom:calc(var(--dhpm)*123) ;
    margin-left: calc(var(--dwpm)*23);
    color: #272A27;
    font-family: "SkModernist-Bold";

}
.featuresContainer {
    display: flex;
    flex-direction: column;
    position: relative;

}
.featuresContainer h4{
    font-family: "SkModernist-Bold";
    font-size: calc(var(--dwpm)*22) ;
    line-height:calc(var(--dwpm)*26);
    text-transform: none;
    color: #F9F3F3;
    font-weight: 700;
    margin-bottom: calc(var(--dhpm)*16);

  }
.featuresContainer li {
    font-family: "SkModernist-Regular";
    font-size: calc(var(--dwpm)*17) ;
    text-transform: none;
    color: #F9F3F3;
    font-weight: 400;
    margin-left: 5%;
    margin-bottom: calc(var(--dhpm)*10);
  }
.featuresContainer2 {
  display: flex;
  flex-direction: column;
  position: relative;

}
.featuresContainer2 h4{
  font-family: "SkModernist-Bold";
  font-size: calc(var(--dwpm)*22) ;
  line-height:calc(var(--dwpm)*26);
  text-transform: none;
  color: #272A27;
  font-weight: 700;
  margin-bottom: calc(var(--dhpm)*16);

}
.featuresContainer2 li {
  font-family: "SkModernist-Regular";
  font-size: calc(var(--dwpm)*17) ;
  text-transform: none;
  color: #272A27;
  font-weight: 400;
  margin-left: 5%;
  margin-bottom: calc(var(--dwpm)*10);
}
.headerTitle{
    display: flex;
    flex-direction: row;
}


.mainFeatures-Container{
    display: flex;
    margin-bottom:calc(var(--dhpm)*123);
    flex-direction: column;
    width: 100%;
}
.iconPart-container{
    display:flex;
    justify-content: center;
    align-items: center;
}
.iconPart{
    display: flex;
    flex-direction: column;
    height:calc(var(--dwpm)*180);
    width:calc(var(--dwpm)*180);
    border-radius: 8px;
    background:url("../../assets/images/sec2IconBckgrnd.png");
    background-size: cover;
    justify-content: center;
    align-items: center;
}

.section2-icon1{
    height:calc(var(--dwpm)*112) ;
    width:calc(var(--dwpm)*110) ;
}
.section2-icon2{
   
    height:calc(var(--dwpm)*107);
    width: calc(var(--dwpm)*115);
}
.section2-icon3{
  
    height:calc(var(--dwpm)*97)  ;
    width:calc(var(--dwpm)*125) ;
}
.section2-icon4{

    height:calc(var(--dwpm)*120) ;
    width:calc(var(--dwpm)*102);
}
.section2-icon5{

    height:calc(var(--dwpm)*111) ;
    width:calc(var(--dwpm)*111) ;
}
.section2-icon6{

    height: calc(var(--dwpm)*121);
    width: calc(var(--dwpm)*101);
}
.slide {
    -webkit-animation: slideIn 1.2s forwards;
    -moz-animation: slideIn 1.2s forwards;
    animation: slideIn 1.2s forwards;
  }
  
  @-webkit-keyframes slideIn {
    0% {
      transform: translateX(-200px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @-moz-keyframes slideIn {
    0% {
      transform: translateX(-200px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideIn {
    0% {
      transform: translateX(-200px);
    }
    100% {
      transform: translateX(0);
    }
  }

  .slide2 {
    -webkit-animation: slideIn2 1.2s forwards;
    -moz-animation: slideIn2 1.2s forwards;
    animation: slideIn2 1.2s forwards;
  }
  
  @-webkit-keyframes slideIn2 {
    0% {
      transform: translateX(200px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @-moz-keyframes slideIn2 {
    0% {
      transform: translateX(200px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideIn2 {
    0% {
      transform: translateX(200px);
    }
    100% {
      transform: translateX(0);
    }
  }
  }