:root{
    --df:calc(100vh/982);
    --dfr:calc(100vw/982);
    --dwp:calc(100vw/1512);
    --dwpm:calc(100vw/390);
    --dhpm:calc(100vh/844);
  }

.section3-part2-container{
    position: relative;        
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: url("../../assets/images/sec3bkgrnd.png");
    flex-direction: column;
    display: flex;
    z-index: 99;
}
.section3-part2-subcontainer{
    display: flex;
    position: relative;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background: url("../../assets/images/sec3bkgrnd.png");
    background-size: cover;
    z-index: 99;
}
.sec3-2-box1-container{
    display: flex;
    justify-content: center;
}

.sec3-2box1{
    position: relative;
    display: flex;
    background-image: url("../../assets/images/sec3.0bkgrnd.svg");
    background-size: cover;
    height: calc(var(--dwp2)*904.4);
    width: calc(var(--dwp2)*1062.96);
    margin-bottom: calc(var(--dwp)*221);
    margin-top: calc(var(--dwp)*271);
    margin-left: calc(var(--dwp)*100);
    

}
.sec3-2box1-inner-box{
    position: absolute;
    width: calc(var(--dwp2)*600);
    height: calc(var(--dwp2)*725);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.77) 0%, rgba(255, 255, 255, 0) 100%);
    filter: drop-shadow(2px 2px 8px rgba(39, 42, 39, 0.23));
    backdrop-filter: blur(12px);
    bottom:calc(var(--dwp2)*60);
    left:calc(var(--dwp2)*5);
    border-radius: calc(var(--dwp2)*41);
}
.sec3-2box1-inner-box ul {
    padding: calc(var(--dwp2)*60);
}
.sec3-2box1-inner-box li {
    font-family: 'SkModernist-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: calc(var(--dwp2)*34);
    line-height: calc(var(--dwp2)*62);
    color: #272A27;
    list-style: inside;
    margin-bottom:calc(var(--dwp2)*20); ;
}

.sec3-2-box2-container{
    position: relative;
    display: flex;
    justify-content: center;
 
}

.sec3-2-box2{
    position: relative;
    display: flex;
    height: calc(var(--dwp2)*627);
    width: calc(var(--dwp2)*1240);
    margin-bottom: calc(var(--dwp)*84);
}

.sec3-2-box2-inner-box{
    position: absolute;
    width: calc(var(--dwp2)*688);
    height: calc(var(--dwp2)*424);
    background: url("../../assets/images/sec3.1bkgrnd.png");
    background-size: cover;
    filter: drop-shadow(2px 2px 8px rgba(39, 42, 39, 0.23));
    border-radius: calc(var(--dwp2)*41);
}

.sec3-2-box2-inner-box a {
    font-family: 'SkModernist-Bold';
    font-size:calc(var(--dwp2)*34);
    font-weight: 700;
    line-height: calc(var(--dwp2)*41);
    letter-spacing: 0em;
    text-align: left;
    color: #184351;    

}
.sec3-2-box2-inner-box ul{
    margin-top: calc(var(--dwp2)*12);}

.sec3-2-box2-inner-box li {
    font-family: 'SkModernist-Regular';
    font-size:calc(var(--dwp2)*22);
    font-weight: 700;
    line-height: calc(var(--dwp2)*26);
    letter-spacing: 0em;
    text-align: left;
    list-style: inside;
    padding-right: calc(var(--dwp2)*136);
    margin-bottom: calc(var(--dwp2)*6);
    color:#272A27;

}

.sec3-2-box2-inner-box-text{
    padding: calc(var(--dwp2)*41);
}
.inner-ul{
    margin-left: calc(var(--dwp2)*41);
    margin-right: calc(var(--dwp2)*41);

}
.sec3-2-box2-image{
    position: absolute;
    height:calc(var(--dwp2)*424) ;
    width: calc(var(--dwp2)*688);
    right: 0;
    bottom: 0;
}

.learn-more-1{
    position: absolute;
    right: calc(var(--dwp2)*177);
}

.learn-more-1 a {
    font-family:"SkModernist-Bold";
    font-size:calc(var(--dwp2)*20);
    font-weight: 700;
    line-height: calc(var(--dwp2)*24);
    letter-spacing: 0em;
    text-align: left;
    width: fit-content;
    border-bottom: 1px solid black;
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
}

.learn-more-2{
    position: absolute;
    right: calc(var(--dwp2)*41);
}

.learn-more-2 a {
    font-family:"SkModernist-Bold";
    font-size:calc(var(--dwp2)*20);
    font-weight: 700;
    line-height: calc(var(--dwp2)*24);
    letter-spacing: 0em;
    text-align: left;
    width: fit-content;
    border-bottom: 1px solid black;
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
}
.learn-more-last{
    position: absolute;
    right: calc(var(--dwp2)*50);
    bottom:calc(var(--dwp2)*50);
}

.learn-more-last a {
    font-family:"SkModernist-Bold";
    font-size:calc(var(--dwp2)*20);
    font-weight: 700;
    line-height: calc(var(--dwp2)*24);
    letter-spacing: 0em;
    text-align: left;
    width: fit-content;
    border-bottom: 1px solid black;
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
}
.sec3-2-box3-container{
    display: flex;
    justify-content: center;
}

.sec3-2-box3{
    position: relative;
    display: flex;
    height: calc(var(--dwp2)*1045);
    width: calc(var(--dwp2)*1240);
    margin-bottom: calc(var(--dwp)*-84);
}

.sec3-2-box3-innerBox-1{
    position: absolute;
    height:calc(var(--dwp2)*824);
    width:calc(var(--dwp2)*552);
    border-radius: calc(var(--dwp2)*41);
    background: url("../../assets/images/sec3.2bkgrnd.png");
    background-size: cover;
    left:0;    

}

.sec3-2-box3-innerBox-2{
    position: absolute;
    height:calc(var(--dwp2)*824);
    width:calc(var(--dwp2)*552);
    border-radius: calc(var(--dwp2)*41);
    background: url("../../assets/images/sec3.3bkgrnd.png");
    background-size: cover;
    bottom:0;
    right: 0; 
}

.sec3-2-box3-innerBox-1-textPart{
    padding-left: calc(var(--dwp2)*41);
    padding-right: calc(var(--dwp2)41);
    padding-top: calc(var(--dwp2)*30);
}

.sec3-2-box3-innerBox-1-textPart a{
    color:#184351;
    font-family: "SkModernist-Bold";
    font-size:calc(var(--dwp2)*34);
    font-weight: 700;
    line-height: calc(var(--dwp2)*41);
    letter-spacing: 0em;
    text-align: left;
}
.sec3-2-box3-innerBox-1-textPart ul{
    margin-top: calc(var(--dwp2)*12);
    margin-bottom:calc(var(--dwp2)*12);

}
.sec3-2-box3-innerBox-1-textPart li{
    font-family: "SkModernist-Regular";
    font-size: calc(var(--dwp2)*22);
    font-weight: 700;
    line-height: calc(var(--dwp2)*26);
    letter-spacing: 0em;
    text-align: left;
    list-style: inside;
    margin-bottom:calc(var(--dwp2)*6);
    color:#272A27;


}

.sec3-2-box3-innerBox-2-textPart{
    padding-left: calc(var(--dwp2)*41);
    padding-right: calc(var(--dwp2)*41);
    padding-top: calc(var(--dwp2)*453);
}

.sec3-2-box3-innerBox-2-textPart a{
    color:#F9F3F3;
    font-family: "SkModernist-Bold";
    font-size:calc(var(--dwp2)*34);
    font-weight: 700;
    line-height: calc(var(--dwp2)*41);
    letter-spacing: 0em;
    text-align: left;
}
.sec3-2-box3-innerBox-2-textPart ul{
    margin-top: calc(var(--dwp2)*12);
    margin-bottom:calc(var(--dwp2)*12);

}
.sec3-2-box3-innerBox-2-textPart li{
    font-family: "SkModernist-Regular";
    font-size: calc(var(--dwp2)*22);
    font-weight: 700;
    line-height: calc(var(--dwp2)*26);
    letter-spacing: 0em;
    text-align: left;
    list-style: inside;
    margin-bottom:calc(var(--dwp2)*6);
    color:#272A27;

}

.sec3-2-box4-container{
    display: flex;
    justify-content: center;
}

.sec3-2-box4{
    position: relative;
    display: flex;
    height: calc(var(--dwp2)*826);
    width: calc(var(--dwp2)*1240);
    background: url("../../assets/images/sec3.4bkgrnd.png");
    background-size: cover;
    margin-bottom: calc(var(--dwp)*357);
}
.sec3-2-box4-innerBox{
    position: absolute;
    display: flex;
    flex-direction: column;
    height: calc(var(--dwp2)*272);
    width: calc(var(--dwp2)*606);
    bottom:  calc(var(--dwp2)*84);
    right: calc(var(--dwp2)*41);
}

.sec3-2-box4-innerBox a{
    color:#F9F3F3;
    font-family: "SkModernist-Bold";
    font-size:calc(var(--dwp2)*34);
    font-weight: 700;
    line-height: calc(var(--dwp2)*41);
    letter-spacing: 0em;
    text-align: left;
}
.sec3-2-box4-innerBox ul{
    margin-top: calc(var(--dwp2)*12);
    margin-bottom:calc(var(--dwp2)*12);

}
.sec3-2-box4-innerBox li{
    font-family: "SkModernist-Regular";
    font-size: calc(var(--dwp2)*22);
    font-weight: 700;
    line-height: calc(var(--dwp2)*26);
    letter-spacing: 0em;
    text-align: left;
    list-style: inside;
    margin-bottom:calc(var(--dwp2)*6);
    color:#272A27;

}


@media screen and (max-width: 500px) {
    .section3-part2-m-container{
        position: relative;        
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background: url("../../assets/images/sec3bkgrndmobile.png");
        flex-direction: column;
        display: flex;
        z-index: 99;
    }
        .section3-part2-m-subcontainer{
        display: flex;
        flex-direction: column;
        background: url("../../assets/images/sec3bkgrndmobile.png");
        background-size: cover;
    }
    .sec3-2-box1-m-container{
        display: flex;
        justify-content: center;
    }
    
    .sec3-2box1-m{
        position: relative;
        display: flex;
        background-image: url("../../assets/images/sec3.0bkgrndmobile.svg");
        background-size: cover;
        height: calc(var(--dwpm)*723);
        width: calc(var(--dwpm)*362);
        margin-bottom: calc(var(--dhpm)*200);
        margin-top: calc(var(--dhpm)*212);
    }
    .sec3-2box1-inner-box-m{
        position: absolute;
        width: calc(var(--dwpm)*342);
        height: calc(var(--dwpm)*494);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.77) 0%, rgba(255, 255, 255, 0) 100%);
        filter: drop-shadow(2px 2px 8px rgba(39, 42, 39, 0.23));
        backdrop-filter: blur(12px);
        border-radius: 41px;
        bottom:calc(var(--dwpm)*0);
        left:calc(var(--dwpm)*5)
    }
    .sec3-2box1-inner-box-m ul {
        padding: calc(var(--dwpm)*22);
    }
    .sec3-2box1-inner-box-m li {
        font-family: 'SkModernist-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: calc(var(--dwpm)*26);
        line-height: calc(var(--dwpm)*31.2);
        color: #272A27;
        list-style: inside;
        margin-bottom:calc(var(--dwpm)*20); ;
    }
    


    .sec3-2-box2-container-m{
        display: flex;
        justify-content: center;
     
    }
    
    .sec3-2-box2-m{
        position: relative;
        display: flex;
        height: calc(var(--dwpm)*638);
        width: 100%;
        margin-bottom: calc(var(--dhpm)*123);
    }
    
    .sec3-2-box2-inner-box-m{
        position: absolute;
        top: calc(var(--dwpm)*12*-1);
        left: calc(var(--dwpm)*25*-1); 
        width: calc(var(--dwpm)*368);
        height: calc(var(--dwpm)*548);
        background: url("../../assets/images/sec3.1mobile.png");
        background-size: cover;
        filter: drop-shadow(2px 2px 8px rgba(39, 42, 39, 0.23));
        border-radius: 41px;
    }
    
    .sec3-2-box2-inner-box-m a {
        font-family: 'SkModernist-Bold';
        font-size:calc(var(--dwpm)*26);
        font-weight: 700;
        line-height: calc(var(--dwpm)*31.2);
        letter-spacing: 0em;
        text-align: left;
        color:#184351;
    
    }
    .sec3-2-box2-inner-box-m ul{
        margin-top: calc(var(--dwpm)*12);}
    
    .sec3-2-box2-inner-box-m li {
        font-family: 'SkModernist-Regular';
        font-size:calc(var(--dwpm)*20);
        font-weight: 700;
        line-height: calc(var(--dwpm)*24);
        letter-spacing: 0em;
        text-align: left;
        list-style: inside;
        margin-bottom: calc(var(--dwpm)*6);
        color:#272A27
    }
    
    .sec3-2-box2-inner-box-text-m{
        padding-right: calc(var(--dwpm)*22);
        padding-left: calc(var(--dwpm)*33);
        padding-top:calc(var(--dwpm)*35) ;
    }



    .inner-ul{
        margin-left: calc(var(--dwp)*41);
        margin-right: calc(var(--dwp)*41);
    
    }
    .sec3-2-box2-image-m{
        position: absolute;
        height:calc(var(--dwpm)*241) ;
        width: calc(var(--dwpm)*298);
        right: calc(var(--dwpm)*12*-1);
        bottom: calc(var(--dwpm)*32*-1);
    }
    
    .learn-more-1{
        position: absolute;
        margin-top:calc(var(--dwpm)*18);
        right: calc(var(--dwpm)*32);
    }
    
    .learn-more-1 a {
        font-family:"SkModernist-Bold";
    font-size:calc(var(--dwpm)*20);
    font-weight: 700;
    line-height: calc(var(--dwpm)*24);
    letter-spacing: 0em;
    text-align: left;
    width: fit-content;
    border-bottom: 1px solid black;
    background: url("../../assets/images/textTwoBack.png");
        background-attachment: center;
        background-size: cover;  
        background-clip: text;
        background-position:center;
        -webkit-background-clip: text;
        color: transparent;
    
    }
    
    .learn-more-2{
        position: absolute;
        right: calc(var(--dwpm)*32);
    }
    
    .learn-more-2 a {
        font-family:"SkModernist-Bold";
        font-size:calc(var(--dwpm)*20);
        font-weight: 700;
        line-height: calc(var(--dwpm)*24);
        letter-spacing: 0em;
        text-align: left;
        width: fit-content;
        border-bottom: 1px solid black;
        background: url("../../assets/images/textTwoBack.png");
        background-attachment: center;
        background-size: cover;  
        background-clip: text;
        background-position:center;
        -webkit-background-clip: text;
        color: transparent;
    }
    .learn-more-3{
        position: absolute;
        right: calc(var(--dwpm)*32);
        margin-top: calc(var(--dwpm)*6);
    }
    
    .learn-more-3 a {
        font-family:"SkModernist-Bold";
        font-size:calc(var(--dwpm)*20);
        font-weight: 700;
        line-height: calc(var(--dwpm)*24);
        letter-spacing: 0em;
        text-align: left;
        width: fit-content;
        border-bottom: 1px solid black;
        background: url("../../assets/images/textTwoBack.png");
        background-attachment: center;
        background-size: cover;  
        background-clip: text;
        background-position:center;
        -webkit-background-clip: text;
        color: transparent;
    }
    .learn-more-4{
        position: absolute;
        right: calc(var(--dwpm)*32);
        bottom:calc(var(--dwpm)*35);
        margin-top: calc(var(--dwpm)*6);
    }
    
    .learn-more-4 a {
        font-family:"SkModernist-Bold";
        font-size:calc(var(--dwpm)*20);
        font-weight: 700;
        line-height: calc(var(--dwpm)*24);
        letter-spacing: 0em;
        text-align: left;
        width: fit-content;
        border-bottom: 1px solid black;
        background: url("../../assets/images/textTwoBack.png");
        background-attachment: center;
        background-size: cover;  
        background-clip: text;
        background-position:center;
        -webkit-background-clip: text;
        color: transparent;
    }
    
    .sec3-2-box3-container{
        display: flex;
        justify-content: center;
    }
    
    .sec3-2-box3{
        position: relative;
        display: flex;
        height: calc(var(--dwpm)*1836);
        margin-bottom: calc(var(--dhpm)*200);
    }
    
    .sec3-2-box3-innerBox-1{
        
        position: absolute;
        height:calc(var(--dwpm)*904);
        width:calc(var(--dwpm)*368);
        background: url("../../assets/images/sec3.2bkgrndmobile.png");
        background-size: cover;
    }
    
    .sec3-2-box3-innerBox-2{
        position: absolute;
        height:calc(var(--dwpm)*858);
        width:calc(var(--dwpm)*368);
        background: url("../../assets/images/sec3.3bkgrndmobile.png");
        background-size: cover;
        left: calc(var(--dwpm)*-55); 
    }
    
    .sec3-2-box3-innerBox-1-textPart{
        padding-left: calc(var(--dwpm)*33);
        padding-right: calc(var(--dwpm)*22);
        padding-top: calc(var(--dwpm)*35);
    }
    
    .sec3-2-box3-innerBox-1-textPart a{
        font-family: 'SkModernist-Bold';
        font-size:calc(var(--dwpm)*26);
        font-weight: 700;
        line-height: calc(var(--dwpm)*31.2);
        letter-spacing: 0em;
        text-align: left;
        color:#184351;
    }
    .sec3-2-box3-innerBox-1-textPart ul{
        margin-top: calc(var(--dwpm)*12);
        margin-bottom:calc(var(--dwpm)*12);
    
    }
    .sec3-2-box3-innerBox-1-textPart li{
        font-family: 'SkModernist-Regular';
        font-size:calc(var(--dwpm)*20);
        font-weight: 700;
        line-height: calc(var(--dwpm)*24);
        letter-spacing: 0em;
        text-align: left;
        list-style: inside;
        margin-bottom: calc(var(--dwpm)*6);
        color:#272A27
    
    
    }
    
    .sec3-2-box3-innerBox-2-textPart{
        padding-left: calc(var(--dwpm)*40);
        padding-right: calc(var(--dwpm)*22);
        padding-top: calc(var(--dwpm)*450);
    }
    
    .sec3-2-box3-innerBox-2-textPart a{
        color:#F9F3F3;
        font-family: 'SkModernist-Bold';
        font-size:calc(var(--dwpm)*26);
        font-weight: 700;
        line-height: calc(var(--dwpm)*31.2);
        letter-spacing: 0em;
        text-align: left;
    }
    .sec3-2-box3-innerBox-2-textPart ul{
        margin-top: calc(var(--dwpm)*12);
        margin-bottom:calc(var(--dwpm)*12);
    
    }
    .sec3-2-box3-innerBox-2-textPart li{
        font-family: 'SkModernist-Regular';
        font-size:calc(var(--dwpm)*20);
        font-weight: 700;
        line-height: calc(var(--dwpm)*24);
        letter-spacing: 0em;
        text-align: left;
        list-style: inside;
        margin-bottom: calc(var(--dwpm)*12);
        color:#272A27;
    
    }
    
    .sec3-2-box4-container-m{
        display: flex;
        justify-content: center;
        margin-bottom: calc(var(--dhpm)*199);
    }
    
    .sec3-2-box4-m{
        position: relative;
        display: flex;
        height: calc(var(--dwpm)*876);
        width: calc(var(--dwpm)*390);
        background: url("../../assets/images/sec3.4bkgrndmobile.png");
        background-size: cover;
    }
    .sec3-2-box4-innerBox-m{
        position: absolute;
        display: flex;
        flex-direction: column;
        height: calc(var(--dwpm)*314);
        width: calc(var(--dwpm)*324);
        bottom:  calc(var(--dwpm)*84);
        right: calc(var(--dwpm)*21);
    }
    
    .sec3-2-box4-innerBox-m a{
        color:#F9F3F3;
        font-family: 'SkModernist-Bold';
        font-size:calc(var(--dwpm)*26);
        font-weight: 700;
        line-height: calc(var(--dwpm)*31.2);
        letter-spacing: 0em;
        text-align: left;
    }
    .sec3-2-box4-innerBox-m ul{
        margin-top: calc(var(--dwpm)*12);
        margin-bottom:calc(var(--dwpm)*12);
    
    }
    .sec3-2-box4-innerBox-m li{
        font-family: 'SkModernist-Regular';
        font-size:calc(var(--dwpm)*20);
        font-weight: 700;
        line-height: calc(var(--dwpm)*24);
        letter-spacing: 0em;
        text-align: left;
        list-style: inside;
        margin-bottom: calc(var(--dwpm)*12);
        color:#272A27;
    
    }


}