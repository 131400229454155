/*---- Main Navigation ----*/
:root{
  --df:calc(100vh/982);
  --dwpm:calc(100vw/390);
  --dhpm:calc(100vh/844);
}

.logo {
  height: calc(var(--dfx) * 137.4);
  width: calc(var(--dfx) * 120);
  margin-top: calc(var(--dfx) * 60);
  margin-left: calc(var(--dfx) * 60);
  float: left;

}
.nav-container{
  display: flex;

}
.main-nav {
  float: right;
  list-style: none;
  margin-top:calc(var(--dfx) * 98.7);
}

.main-nav li {
  display: inline-block;
}


.main-nav li a:link,
.main-nav li a:visited {
  font-family: "SkModernist-Regular";
  font-weight: 400;
  color: #272A27;
  text-decoration: none;
  font-size: calc(var(--dfx) * 20);;  
  border-bottom: 0 ;
  
}

.main-nav li .navigation-ScheduleAppointment a{
  

  font-family: "SkModernist-Bold";
  font-weight: 400;
  background: url("../../assets/images/textTwoBack.png");
  background-attachment: center;
  background-size: cover;  
  background-clip: text;
  background-position:center;
  -webkit-background-clip: text;
  color: transparent;;
  text-decoration: none;
  font-size: calc(var(--dfx) * 20);}

 .navigation-ScheduleAppointment{
  font-family: "SkModernist-Bold";
  font-weight: 400;
  background: url("../../assets/images/textTwoBack.png");
  background-attachment: center;
  background-size: cover;  
  background-clip: text;
  background-position:center;
  -webkit-background-clip: text;
  color: transparent;;
  text-decoration: none;
  font-size: calc(var(--dfx) * 20);
  border-bottom: 1px solid black ;
}


.main-nav li a:hover,
.main-nav li a:active {
  border-bottom: 0px ;
}

/*---- Mobile Navigation ----*/
.mobile-nav-icon {
  float: right;
  margin-top: 30px;
  cursor: pointer;
  display: none;
}

.mobile-nav-icon ion-icon {
  font-size: 200%;
  color: black;
}

/*---- Sticky Navigation ----*/

.logo-black {
  display: none;
  height: 50px;
  width: auto;
  float: left;
  margin: 5px 0;
}


.notSticky{
  width: 100%;
  font-family: "SkModernist-Regular";

}

.notSticky .main-nav {


}


.notSticky .ul{
  background-color: antiquewhite;
}
.sticky .logo {
  display: none;
  margin-left: 100px;
}
.sticky .logo-black {
  display: block;
}


.mobile-nav-icon {
  float: right;
  margin-top:calc(var(--dwpm) * 40);
  margin-right:calc(var(--dwpm) * 20);
  cursor: pointer;
  display: none;
}

.mobile-nav-icon ion-icon {
  height: calc(var(--dwpm) * 40);
  width: calc(var(--dwpm) * 40);
  font-size: 200%;
  color:  #184351;
  ;
}


/*---- Responsive ----*/

/* Big Tablets to 1200px (widths smaller than the 1140px row) */
@media only screen and (max-width: 1200px) {
}

/* Small Tablet to Big Tablets: 768px to 1023px */
@media only screen and (max-width: 1023px) {
}

/* Small Phones to Small Tablets: 481px to 767px */


/* Small Phone: 0 to 480px */
@media only screen and (max-width: 700px) {
}

@media screen and (max-width: 500px) {
  .logo {
    height: calc(var(--dwpm) * 68.7);
    width: calc(var(--dwpm) * 60);
    margin-top: calc(var(--dwpm) * 22);
    margin-left: calc(var(--dwpm) * 22);
    float: left;
  
  }
  .nav-container{
    display: flex;
  
  }
  .main-nav {
    position: absolute;
    float: right;
    list-style: none;
    margin-top:calc(var(--dwpm) * 45);
    margin-left:calc(var(--dwpm) * 120);
  
  }
  
  .main-nav li {
    display: inline-block;
  }
  
  
  .main-nav li a:link,
  .main-nav li a:visited {
    font-family: "SkModernist-Regular";
    font-weight: 400;
    color: #272A27;
    text-decoration: none;
    font-size:calc(var(--df) * 20);
    border-bottom: 0 ;  
  }
  .main-nav li .navigation-ScheduleAppointment a{
    font-family: "SkModernist-Bold";
    font-weight: 400;
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;;
    text-decoration: none;
    font-size: calc(var(--dwpm) * 16);
  }

  
  





  .main-navm.hidden { 
    position: absolute;
    top: -9999px;
    left: -9999px;
    transform: translateY(-120%);
}
.main-navm.show { 
    visibility: visible; 
    opacity: 1; 
    transform: translateY(0%);
    transition: transform .5s linear;
}

.mobile-nav-icon { display: inline-block;  color: black;
}
.mobile-nav-icon2 {
  position: relative;
  float: right;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: calc(var(--dwpm)*10);
  margin-top: calc(var(--dwpm)*10);

}

.mobile-nav-icon2 ion-icon {
  height: calc(var(--dwpm) * 40);
  width: calc(var(--dwpm) * 40);
  font-size: 200%;
  color: black;
  margin-right: calc(var(--dwpm) *20);

}
.main-navm{ 
    backdrop-filter: blur(20px);
    padding-top: calc(var(--dhpm) *45);
    position: absolute;
    float: left;
    top: 0px;
    right: 0px; 
    z-index: 99;
    border-bottom-left-radius: 41px;
    border: 1px solid #F9F3F3;
    align-items: center;
    justify-content: center;

}
.main-nav ul{
  margin-top: 20px;
}
.main-navm li { 
    display: block;
 }

.main-navm li a:link,
.main-navm li a:visited {
    font-family: "SkModernist-Bold";
    display: block;
    border: 0;
    padding: 20px 0;
    font-size: calc(var(--dwpm) * 16);
    margin-left: calc(var(--dwpm)*20);
    margin-right: calc(var(--dwpm)*10);
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;;
    text-decoration: none;
}
.navigation-ScheduleAppointment{
  
  padding-bottom: 40px;  border: 0;
  }  
  .navigation-ScheduleAppointment2{
    padding-top: 0;
    padding-bottom: 20px 0;
    border: 0;
    }  

    .main-nav li .navigation-ScheduleAppointment2 a{ 
    padding-bottom: 0px;
    border-bottom: 1px solid black;
    font-family: "SkModernist-Bold";
    font-weight: 400;
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;;
    text-decoration: none;
    font-size: calc(var(--dwpm) * 16);
    } 
.main-navm li .navigation-ScheduleAppointment a{   
  padding-bottom: 0px;
  border-bottom: 1px solid black;
  margin-left: calc(var(--dwpm)*20);
  margin-right: calc(var(--dwpm)*10);
  font-family: "SkModernist-Bold";
  font-weight: 400;
  background: url("../../assets/images/textTwoBack.png");
  background-attachment: center;
  background-size: cover;  
  background-clip: text;
  background-position:center;
  -webkit-background-clip: text;
  color: transparent;;
  text-decoration: none;
  font-size: calc(var(--dwpm) * 16);
}
 
}
