/*----- Reusable ----- */
.row {
    max-width: 1140px;
    margin: 0 auto;
     
}

section {
    padding: 80px 0px;

}

.box {
    padding: 1%;
    
}

/* ----- Paragraphs ----- */
.long-copy {
    line-height: 145%;
    width: 70%;
    margin-left: 15%;
    font-family: 'Modernist', 'Modernist-Regular', sans-serif; 
}

.box p {
    font-size: 90%;
    line-height: 145%;
    font-family: 'Modernist', 'Modernist-Regular', sans-serif; 
}

/*----- Headings -----*/
h1, 
h2, 
h3 {
    font-weight: 300;
    text-transform: uppercase;
    font-family: 'Modernist', 'Modernist-Regular', sans-serif; 
  }
  @font-face {
    font-family: 'Modernist';
    src: local('Modernist'), url(./fonts/SkModernist-Bold.otf) format('truetype');
  }

h1 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #fff;
    font-family: 'Modernist', 'Modernist-Regular', sans-serif;  
    font-size: 240%;
    word-spacing: 4px;
    letter-spacing: 1px; 
}

h2 {
    font-size: 180%;
    word-spacing: 2px;
    font-family: 'Modernist', 'Modernist-Regular', sans-serif;  
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 1px; 
}

h2:after {
    display: block;
    height: 2px;
    background-color: #184351;
    font-family: 'Modernist', 'Modernist-Regular', sans-serif;  
    content: " ";
    width: 100px;
    margin: 0 auto;
    margin-top: 30px;
}

h3 {
    font-size: 110%;
    margin-bottom: 15px;
    font-family: 'Modernist', 'Modernist-Regular', sans-serif; 
}
@font-face {
    font-family: 'Modernist';
    src: local('Modernist'), url(./fonts/SkModernist-Regular.otf) format('truetype');
  }
  
/*----- Links -----*/
a:link,
a:visited {
    color: #184351;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px solid #184351;
    -webkit-transition: border-bottom 0.2s, color 0.2s;
    transition: border-bottom 0.2s, color 0.2s;
}

a:hover,
a:active {
    color: #555;
    border-bottom: 1px solid transparent;
}

/*----- Icons-----*/
.icon-big {
    font-size: 350%;
    display: block;
    color: #184351;
    margin-bottom: 10px;
    z-index: 1;
}

.icon-small, 
.icon-small-fail {
    display: inline-block;
    width: 30px;
    text-align: center;
    font-size: 120%;
    font-family: 'Modernist', 'Modernist-Bold', sans-serif;  
    margin-right: 10px;
    line-height: 120%;
    vertical-align: middle;
    margin-top: -5px;
    z-index: 1;
}

.icon-small {
    color: #184351;
}

.icon-small-fail {
    color: #ff0000;
}

/*----- Buttons -----*/

.btn:link, 
.btn:visited,
input[type=submit],
button
 {
    display: inline-block;
    padding: 10px 30px;
    font-weight: 300;
    text-decoration: none;
    border-radius: 200px;
    -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.btn-full:link,
.btn-full:visited,
input[type=submit],
button  {
    background-color: #e67e22;
    border: 1px solid #e67e22;
    color: #fff;
    margin-right: 15px;
}

.btn-ghost:link,
.btn-ghost:visited {
    border: 1px solid #e67e22;
    color: #e67e22;
}

.btn:hover,
.btn:active,
input[type=submit]:hover,
input[type=submit]:active {
    background-color: #cf6d17;
    cursor: pointer;
}

.btn-full:hover,
.btn-full:active,
input[type=submit]  {
    border: 1px solid #cf6d17;
    cursor: pointer;
}

.btn-ghost:hover,
.btn-ghost:active {
    border: 1px solid #cf6d17;
    color: #fff;
    cursor: pointer;
}

/* ----- Media Queries -----*/

/* Big Tablets to 1200px (widths smaller than the 1140px row) */
@media only screen and (max-width: 1200px) {
    .row { padding: 0 2%; }
}


/* Small Tablet to Big Tablets: 768px to 1023px */
@media only screen and (max-width: 1023px) {
    section { padding: 60px 0; }
    .long-copy {
        width: 80%;
        margin-left: 10%;
    }

    .icon-small { 
        margin-right: 5px;
        width: 17px; 
    }
}


/* Small Phones to Small Tablets: 481px to 767px */
@media only screen and (max-width: 767px) {
    section { padding: 40px 0; }
    .row { padding: 0 4%; }
    .col { 
        width: 100%; 
        margin: 0 0 4% 0;
    }
    h1 { font-size: 180%; }
    h2 { font-size: 150%; }

    .long-copy {
        width: 100%;
        margin-left: 0%;
    }
}


/* Small Phone: 0 to 480px */
@media only screen and (max-width: 480px) {
    section { padding: 30px 0; }
}