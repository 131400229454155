:root{
    --dwp:calc(100vw/1512);
    --dwpm:calc(100vw/390);
    --dhpm:calc(100vh/844);
}
.section5to6-container{
    position: relative;
    display: flex;
    width: 100vw;
    height:calc(var(--dwp)*1070) ;
    z-index: 999;
}
.section5to6-img1{
   height: calc(var(--dwp)*607);
   width: calc(var(--dwp)*232);
    z-index: 99;
    position: absolute;
}
.section5to6-img2{
    height: calc(var(--dwp)*375);
    width: calc(var(--dwp)*345);
    z-index: 99;
    position: absolute;
    top:calc(var(--dwp)*639) ;
}
.section5to6-img3{
    height: calc(var(--dwp)*375);
    width: calc(var(--dwp)*232);
    z-index: 99;
    position: absolute;
    left:calc(var(--dwp)*264);
    top:calc(var(--dwp)*89);
}
.section5to6-img4{
    height: calc(var(--dwp)*504);
    width: calc(var(--dwp)*575);
    z-index: 99;
    position: absolute;
    left:calc(var(--dwp)*264);
    top:calc(var(--dwp)*178);
}
.section5to6-img5{
    height: calc(var(--dwp)*375);
    width: calc(var(--dwp)*232);

    z-index: 99;
    position: absolute;
    left:calc(var(--dwp)*377);
    bottom:calc(var(--dwp)*0);
}
.section5to6-img6{
    height: calc(var(--dwp)*232);
    width: calc(var(--dwp)*143);
    z-index: 99;
    position: absolute;
    right: calc(var(--dwp)*669);
    top:calc(var(--dwp)*30);
}
.section5to6-img7{
    height: calc(var(--dwp)*375);
    width: calc(var(--dwp)*607);
    z-index: 99;
    position: absolute;
    right:calc(var(--dwp)*264);
    bottom:calc(var(--dwp)*232);
}
.section5to6-img8{
    height: calc(var(--dwp)*375);
    width: calc(var(--dwp)*375);

    z-index: 99;
    position: absolute;
    right:calc(var(--dwp)*264);
    top:calc(var(--dwp)*64);
}
.section5to6-img9{
    height: calc(var(--dwp)*607);
    width: calc(var(--dwp)*232);

    z-index: 99;
    position: absolute;
    right:calc(var(--dwp)*0);
    top:calc(var(--dwp)*164);
}