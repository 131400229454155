:root{
    --dwp:calc(100vw/1512);
    --dwpm:calc(100vw/390);
    --dhpm:calc(100vh/844);
}
.section4to5-container{
    position: relative;
    flex-direction: column;
    display: flex;
    width: 100vw;
    z-index: 99;
    align-items: center;

}
.section4to5-main-container{
    display: flex;
    flex-direction: row;
    position: relative;

       
}
.section4to5-image-container{
    display: flex;
    width: fit-content;
    align-items: flex-start;
}
.section4to5-image1{
    height:   calc(var(--dwp2)*578);
    width: calc(var(--dwp2)*441);
    margin-left: calc(var(--dwp)*221);
    margin-top: calc(var(--dwp)*136);
}
.section4to5-image2{
    height:  calc(var(--dwp2)*934);
    width: calc(var(--dwp2)*578);
    margin-left: calc(var(--dwp)*136);
    margin-right: calc(var(--dwp)*136);
    margin-top: calc(var(--dwp)*52);
   }
.section4to5-text-part1{
    display: flex;
    flex-direction: column;
}
.section4to5-text-part1 h3{
    margin-left: 10%;
    text-transform: none;
    font-family: 'SkModernist-Bold';
    position: absolute;
    font-size: calc(var(--dwp2)*41);
    font-weight: 700;
    bottom: calc(var(--dwp)*(-15));
    color:#07331E
}

@media screen and (max-width: 500px) {
    .section4to5-m-container{
        flex-direction: column;
        display: flex;
        width: 100vw;
        z-index: 99;
        position: relative;

    
    }
    .section4to5-m-main-container{
        display: flex;
        flex-direction: row;
        position: relative;
       
    }


    .section4to5-m-image1{
        height: calc(var(--dwpm)*322);
        width: calc(var(--dwpm)*246);
        margin-right:calc(var(--dwpm)*10);
        margin-top:calc(var(--dwpm)*120); 
    }

    .section4to5-m-image2{
        height: calc(var(--dwpm)*522);
        width: calc(var(--dwpm)*348.25);
        margin-left:calc(var(--dwpm)*10);

  
       }
 
}