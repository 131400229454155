:root{
  --dfx:calc(100vh/982);
  --dwpm:calc(100vw/390);
  --dhpm:calc(100vh/844);
}
.sec1-image{
  position: absolute;
  right:calc(var(--dfx)*200*-1);
  bottom:calc(var(--dfx)*180*-1);
  height:calc(var(--dfx)*942); 
  width: calc(var(--dfx)*1065);
}
.header-top-container {
  position: relative;
  display: flex;
  flex-direction: column;
 background: #F9F3F3;
  height: 100vh;

}
.sub-nav-container{
  display: flex;


}
.hero-text-box {
  position: relative;
  display: flex;
  margin-top:5vh;
  margin-left: calc(var(--dwp)*136);
  width:62vw;
  
  
}

.main-container{
  position: relative;
  display: flex;
  flex-direction: column;
}
.containera {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-bottom:1.2vh ;
  width: calc(var(--dfx)*933);
}
.containera2 {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-bottom:2.2vh ;
}
.containera p{
  font-family: "SkModernist-Bold";
  font-weight: 700;
  font-size: calc(var(--dfx) * 45);
  color: #184351;
}
.containera2 p{
  font-family: "SkModernist-Bold";
  font-weight: 700;
  font-size: calc(var(--dfr) * 41);
  text-transform: uppercase;
  background: url("../../assets/images/header1bck.png");
  background-attachment: initial;  
  background-size: cover;
  background-clip: text;
  background-position: center;
  -webkit-background-clip: text;
  color: transparent;
}
.header-container2 {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-bottom: 4.4vh;
  width: calc(var(--dfx)*690);
}
.header-container2 p{
  width: fit-content;
  text-transform: none;
  font-family: "SkModernist-Regular";
  font-size: calc(var(--dfx) * 32);
  color: #6D170E;
}
.header-container2b {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-bottom: 6.4vh;
}
.header-container2b p{
  width: fit-content;
  text-transform: none;
  font-family: "SkModernist-Bold";
  font-size: calc(var(--dfx) * 20);
  background: url("../../assets/images/headerback2.png");
  color: #272A27;
}
.header-container3 {
  width: calc(var(--dfx)*690);
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-bottom: 0.5vh;

}
.header-container3 p{
  width: fit-content;
  text-transform: none;
  font-family: "SkModernist-Regular";
  font-weight: bold;
  font-size: calc(var(--dfx) * 20);
  color: #272A27;
}
.header-container3b {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-bottom: 3.2vh;

}
.header-container3b p{
  width: fit-content;
  text-transform: none;
  font-family: "SkModernist-Regular";
  font-weight: bold;
  font-size: calc(var(--dfr) * 22);
  color: #272A27;
}
.header-container4 {
  width: calc(var(--dfx)*690);

  display: flex;
  flex-direction: row;
  text-align: left;
  margin-bottom: 6.4vh;
}

.header-container4 p{
  width: fit-content;
  text-transform: none;
  font-family: "SkModernist-Regular";
  font-weight: bold;
  font-size: calc(var(--dfx) * 20);
  color: #272A27;
}
.header-container4b {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-bottom: 6.4vh;
}

.header-container4b p{
  width: fit-content;
  text-transform: none;
  font-family: "SkModernist-Bold";
  font-weight: bold;
  font-size: calc(var(--dfr) * 22);
  color: #272A27;
}
.header-container5 {
  width: calc(var(--dwp)*1240);


  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: flex-end;
}
.header-container5 a {
  display: flex;
  width: fit-content;
  text-transform: none;
  font-family: "SkModernist-Bold";
  font-size: calc(var(--dfx) * 20);
  background: url("../../assets/images/textTwoBack.png");
  background-attachment: center;
  background-size: cover;  
  background-clip: text;
  background-position:center;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 3.2vh;
}
.header-container5b {
  display: flex;
  flex-direction: row;
  text-align: left;
}
.header-container5b a {
  width: fit-content;
  text-transform: none;
  font-family: "SkModernist-Bold";
  font-size: calc(var(--dfr) * 20);
  background: url("../../assets/images/textTwoBack.png");
  background-attachment: center;
  background-size: cover;  
  background-clip: text;
  background-position:center;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 3.2vh;
}
/* Big Tablets to 1200px (widths smaller than the 1140px row) */
@media only screen and (max-width: 1200px) {
  
}

/* Small Tablet to Big Tablets: 768px to 1023px */
@media only screen and (max-width: 1023px) {
}

/* Small Phones to Small Tablets: 481px to 767px */
@media only screen and (max-width: 767px) {

}

/* Small Phone: 0 to 480px */
@media screen and (max-width: 500px) {
  .sec1-image{
    position: absolute;
    right:calc(var(--dhpm)*100*-1);
    bottom:calc(var(--dhpm)*0*-1);
    height:calc(var(--dhpm)*500);;
    width:calc(var(--dwpm)*600);;
  }
  .header-top-container {
    display: flex;
    flex-direction: column;
    background: #F9F3F3;    height: 100vh;
  
  }
  .sub-nav-container{
  
    display: flex;

  
  }
  .hero-text-box {
    position: relative;
    display: flex;
    margin-top:calc(var(--dhpm)*47);
    margin-left: calc(var(--dwpm)*22);
    margin-right: calc(var(--dwpm)*22);

    width:auto;
  }
  
  .main-container{
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .containera {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-bottom:calc(var(--dhpm)*18);
  }

  .containera p{
    font-family: "SkModernist-Bold";
    font-weight: 700;
    font-size: calc(var(--dhpm) * 23);
    text-transform: uppercase;
    color:#184351;
  }
 
  .header-container2 {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-bottom:calc(var(--dhpm)*20);
    width: auto;

  }
  .header-container2 p{
    width: fit-content;
    text-transform: none;
    font-family: "SkModernist-Regular";
    font-size:calc(var(--dhpm) * 16);
    color: #6D170E;
  }

  
  .header-container3 {
    width: auto;
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-bottom:calc(var(--dhpm)*18);

  }
  .header-container3 p{
    width: fit-content;
    text-transform: none;
    font-family: "SkModernist-Regular";
    font-weight: bold;
    font-size: calc(var(--dhpm) * 17);
    color: #272A27;
  }

 
  .header-container4 {
    width: auto;
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-bottom:calc(var(--dhpm)*47);

  }
  
  .header-container4 p{
    width: fit-content;
    text-transform: none;
    font-family: "SkModernist-Regular";
    font-weight: bold;
    font-size: calc(var(--dhpm) * 17);
    color: #272A27;
  }
 
  

  .header-container5 {
    width: auto;
    display: flex;
    flex-direction: row;
    text-align: right;    
    justify-content: flex-end;
  }
  .header-container5 a {
    display: flex;
    width: fit-content;
    text-transform: none;
    font-family: "SkModernist-Bold";
    font-size: calc(var(--dhpm) * 20);
    background: url("../../assets/images/textTwoBack.png");
    background-attachment: center;
    background-size: cover;  
    background-clip: text;
    background-position:center;
    -webkit-background-clip: text;
    color: transparent;
    border-bottom: 1px solid black;
  }
 
  
}




